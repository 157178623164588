import React from 'react';

import './config/axiosConfig';
import './services/axiosInterceptors';

import {GlobalDataContextProvider} from "./contexts/GlobalDataContext";
import {ViewContextProvider} from "./contexts/ViewContext";

import AppWrapper from "./AppWrapper";

function App() {

    return (
        <div className="app-root">
            <GlobalDataContextProvider>
                <ViewContextProvider>
                    <AppWrapper />
                </ViewContextProvider>
            </GlobalDataContextProvider>
        </div>
    );
}

export default App;
