import {
    viewLoaderActionTypes,
    viewHeaderActionTypes,
    viewModalActionTypes,
    viewRedirectActionTypes,
    viewPageActionTypes, viewSizeActionTypes
} from './viewContextActions';

import {viewModalInitialState, viewRedirectInitialState} from "./constants";
import {modalTypes} from "../../components/layout/modals/ModalWrapper/constants";

export const viewLoaderReducer = (state, action) => {

    switch (action.type) {

        case viewLoaderActionTypes.OpenLoader:
            return {...state, isOpen: action.payload, message:action.message ? action.message : '', isTransparent: false};

        case viewLoaderActionTypes.UpdateLoader:
            return {...state, isOpen: action.payload, isTransparent: true};

        case viewLoaderActionTypes.UpdateMessage:
            return {...state, message: action.payload};

        case viewLoaderActionTypes.OpenWithoutText:
            return {...state, message: '', isOpen: true, isTransparent: true};

        default:
            return state;

    }
}

export const viewModalReducer = (state, action) => {
    switch (action.type) {

        case viewModalActionTypes.OpenFinaleModal:
            return {
                ...state,
                type: modalTypes.Finale,
                isOpen: true
            }

        case viewModalActionTypes.OpenActionModal:
            return {
                ...state,
                type: modalTypes.Action,
                isOpen: true,
                title: action.title,
                actions: {...action.actions}
            }

        case viewModalActionTypes.OpenPostalModal:
            return {
                ...state,
                type: modalTypes.Postal,
                isOpen: true
            };

        case viewModalActionTypes.SubmitAction:
            return {
                ...viewModalInitialState,
                callback: {...action.payload}
            }

        case viewModalActionTypes.OpenMessageRedirectModal:
            return {
                ...state,
                type: modalTypes.MessageRedirect,
                isOpen: true,
                title: action.title,
                description: action.description,
                // submitPath: action.submitPath ? action.submitPath : ''
                submit: action.submit,
            }

        case viewModalActionTypes.OpenMessageModal:
            return {
                ...state,
                type: modalTypes.Message,
                isOpen: true,
                title: action.title,
                description: action.description,
                submitPath: action.submitPath ? action.submitPath : ''
            };

        case viewModalActionTypes.OpenQuoteRequestModal:
            return {
              ...state,
              type: modalTypes.QuoteRequest,
              isOpen: true,
              title: action.title,
              description: action.description,
              phoneNumber: action.phoneNumber,
              submitPath: action.submitPath ? action.submitPath : ''
            };

        case viewModalActionTypes.CloseModal:
            return {...state, isOpen: false};

        case viewModalActionTypes.ClearModal:
            return {...viewModalInitialState};

        default:
            return {state};
    }
};

export const viewSizeReducer = (state, action) => {
    switch (action.type) {

        case viewSizeActionTypes.UpdateScreenSize:
            return {...state, width: action.width, height: action.height};

        case viewSizeActionTypes.UpdateMobile:
            return {...state, isMobile: action.payload}

        default:
            return state;
    }
};

export const viewRedirectReducer = (state, action) => {
    switch (action.type) {

        case viewRedirectActionTypes.Redirect:
            return {...state, isRedirect: true, redirectPath: action.payload};

        case viewRedirectActionTypes.Redirected:
            return viewRedirectInitialState;

        default:
            return state;
    }
};

export const viewPageReducer = (state, action) => {

    switch (action.type) {

        case viewPageActionTypes.UpdatePageMessage:
            return {...state, message: action.data.message};

        case viewPageActionTypes.ClearPageMessage:
            return {...state, message:''};

        case viewPageActionTypes.UpdateQuoteRequest:
            return {...state, quoteRequest: action.payload};

        default:
            return {state}
    }
};

export const viewHeaderReducer = (state, action) => {

    switch (action.type) {

        case viewHeaderActionTypes.UpdateMobileHeader:
            return {...state, isMobileOpen: action.payload};

        case viewHeaderActionTypes.UpdateDesktopHeader:
            return {...state, isDesktopOpen: action.payload};

        case viewHeaderActionTypes.CloseAll:
            return {...state, isMobileOpen: false, isDesktopOpen: false};

        case viewHeaderActionTypes.UpdateVehicleHeader:
            return {...state, vehicleNavigation: action.payload};

        case viewHeaderActionTypes.UpdateVehicleId:
            return {...state, vehicleId: action.payload};

        default:
            return state;
    }
};

export const viewContentReducer = (state, action) => {
    switch (action.type) {

        case viewLoaderActionTypes.UpdateLoader:
            return {...state, isLoader: action.payload}

        default:
            return state;

    }
};
