import React, {useContext, useEffect, useMemo, useState} from "react";
import deleteIcon from "../../../resources/images/close.svg";
import rightTabArrow from "../../../resources/images/right.svg";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import leftTabArrow from "../../../resources/images/left.svg";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import ViewContext from "../../../contexts/ViewContext";
import {viewModalActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import DiscountDataContext, {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext";
import {FormConfigStateContext} from "../../../contexts/formContexts/FormConfigState";

const FormDesktopTab = (props) => {

        const {vehicleTab, updateVehicleTab, updateVehicleData} = useContext(VehicleDataContext);

        const {formConfig} = useContext(FormConfigStateContext);
        const {driverTab, updateDriverTab, updateDriverData} = useContext(DriverDataContext);

        const {updateDiscountData } = useContext(DiscountDataContext);

        const {viewModal, updateViewModal} = useContext(ViewContext);



        const localName = props.isDriver ? 'Driver' : props.isVehicle ? 'Vehicle' : 'Item';


        const localTabs = useMemo(() => {
            if (props.isVehicle) {
                return vehicleTab;
            } else {
                if (props.isDriver) {
                    return driverTab;
                }
            }
        }, [props.isVehicle, props.isDriver, vehicleTab, driverTab]);


        const [currentPage, updateCurrentPaage] = useState(
            !props.isRecalculate ? localTabs.activeItem > 1 ? localTabs.activeItem > 2 ? 2 : 1 : 0 :
                localTabs.activeItem > 1 ? localTabs.activeItem > 2 && localTabs.tabs.length>4 ? 2 : 1 : 0
        );
        const [maxPage, updateMaxPage] = useState(
            !props.isRecalculate ? localTabs.tabs.length > 3 ? localTabs.tabs.length > 4 ? 2 : 1 : 0 :
                localTabs.tabs.length > 3 ? localTabs.tabs.length > 4 ? 2 : 1 : 0
        );

        // control

        function goLeft() {
            if (currentPage > 0) {
                updateCurrentPaage((currentPage - 1));
            }
        }

        function goRight() {
            if (currentPage < maxPage) {
                updateCurrentPaage((currentPage + 1));
            }
        }

        function handleChangeItem(value) {
            if (props.isVehicle) {
                updateVehicleTab({type: vehicleDataActionTypes.SelectItem, payload: value});
            }
            if (props.isDriver) {
                updateDriverTab({type: driverDataActionTypes.SelectItem, payload: value});
            }
            let newCurrentPage = 0;
            if(maxPage>0) {
                if (value > 1) {
                    newCurrentPage = value - 1;
                } else {
                    newCurrentPage = 0;
                }
            }

            if(newCurrentPage>maxPage){
                newCurrentPage = maxPage;
            }

            if(newCurrentPage!==currentPage){
                updateCurrentPaage(newCurrentPage);
            }
        }

        function handleAddItem() {
            if (localTabs.tabs.length < 5) {

                if (localTabs.tabs.length > 1 && localTabs.tabs.length < 4) {
                    updateCurrentPaage(Number(currentPage + 1));
                }
                if (props.isVehicle) {
                    updateVehicleData({type: vehicleDataActionTypes.AddItem});
                    updateVehicleTab({type: vehicleDataActionTypes.AddItem});
                    updateDiscountData({type: discountDataActionTypes.AddVehlink});
                }
                if (props.isDriver){
                    updateDriverData({type: driverDataActionTypes.AddItem, payload: formConfig.licenceConfig.licenceAge});
                    // updateDriverData({type: driverDataActionTypes.AddItem});
                    updateDriverTab({type: driverDataActionTypes.AddItem});
                }
            }
        }

        /* remove part */
        function handleRemoveTab(index) {
            if (props.isVehicle) {
                updateViewModal({
                    type: viewModalActionTypes.OpenActionModal,
                    title: `Are you sure you want to delete ${vehicleTab.tabs[index].name}`,
                    actions: {
                        callback: 'REMOVE_VEHICLE',
                        id: index,
                        state: 'VEHICLE',
                        submit: true
                    }
                });
            }
            if (props.isDriver) {
                updateViewModal({
                    type: viewModalActionTypes.OpenActionModal,
                    title: `Are you sure you want to delete ${driverTab.tabs[index].name}`,
                    actions: {
                        callback: 'REMOVE_VEHICLE',
                        id: index,
                        state: 'DRIVER',
                        submit: true
                    }
                });
            }

        }

        useEffect(() => {
            if(!props.isRecalculate) {
                if (localTabs.tabs.length > 2) {
                    if (localTabs.tabs.length > 3) {
                        updateMaxPage(2);
                    } else {
                        updateMaxPage(1);
                    }
                } else {
                    updateMaxPage(0);
                    updateCurrentPaage(0);
                }
            }
            else{
                if (localTabs.tabs.length > 3) {
                    if (localTabs.tabs.length > 4) {
                        updateMaxPage(2);
                    } else {
                        updateMaxPage(1);
                    }
                } else {
                    updateMaxPage(0);
                    updateCurrentPaage(0);
                }
            }
        }, [localTabs.tabs, props.isRecalculate]);

        useEffect(() => {
            if (viewModal.callback) {
                if (props.isVehicle) {
                    if (viewModal.callback.submit && viewModal.callback.state === 'VEHICLE') {
                        updateVehicleData({type: vehicleDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                        updateVehicleTab({type: vehicleDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                        updateDiscountData({type: discountDataActionTypes.RemoveVehlink, payload:viewModal.callback.id});

                    }
                }
                if (props.isDriver) {
                    if (viewModal.callback.submit && viewModal.callback.state === 'DRIVER') {
                        updateDriverData({type: driverDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                        updateDriverTab({type: driverDataActionTypes.RemoveItem, payload: viewModal.callback.id});

                    }
                }

                updateViewModal({type: viewModalActionTypes.ClearModal});
                if (currentPage > 0) {
                    if (currentPage === 2) {
                        if (localTabs.tabs.length < 5) {
                            updateCurrentPaage(Number(currentPage - 1));
                        }
                    }
                    if (currentPage === 1) {
                        if (localTabs.tabs.length < 4) {
                            updateCurrentPaage(Number(currentPage - 1));
                        }
                    }

                }

            }

        }, [currentPage, localTabs.tabs.length, props.isDriver, props.isVehicle, updateDiscountData, updateDriverData, updateDriverTab, updateVehicleData, updateVehicleTab, updateViewModal, viewModal.callback]);


        return (
            <>
                {
                    currentPage !== 0 &&
                    <div className="tabLeftArrow" onClick={() => goLeft()}>
                        <img src={leftTabArrow} alt="arrow"/>
                    </div>
                }

                <div className="tabNavigationWrapper tabNavigationWrapperDesktopMargin"
                     style={{marginLeft: `${currentPage > 0 ? `-${currentPage * 157}px` : '0'}`}}>


                    {
                        localTabs.tabs.map((tab, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    handleChangeItem(index)
                                }}
                                     className={`singleTab ${index === localTabs.activeItem ? 'activeTab' : 'inactiveTab'}`}
                                >
                                    {localTabs.tabs.length > 1 && index>0 && !props.isRecalculate &&
                                    <img onClick={() => {
                                        handleRemoveTab(index);
                                    }} src={deleteIcon}
                                         className="deleteBtn" alt="delete"/>}
                                    <h4 >
                                        {
                                            tab.name ?
                                                tab.name :
                                                `${localName} ${index + 1}`
                                        }
                                    </h4>

                                    {
                                        // Render Delete modal
                                    }

                                    {/*{modalShown && <DeleteModal*/}
                                    {/*  index={tabIndex}*/}
                                    {/*  modalShown={modalShown}*/}
                                    {/*  customStyles={customStyles}*/}
                                    {/*  modalTabIndex={tabIndex === index && tab.make ? handleCatTabText(tab.make, 15) : tabIndex + 1}*/}
                                    {/*  handleModalClose={handleModalClose}*/}
                                    {/*  handleDelete={handleDelete}*/}
                                    {/*/>}*/}

                                </div>
                            );
                        })
                    }
                    {
                        localTabs.tabs.length <= 4 && !props.isRecalculate &&
                        <div className="singleTab newTab" onClick={() => {
                            handleAddItem()
                        }}>
                            <h4> Add a {localName}</h4>
                        </div>
                    }


                </div>

                {
                    currentPage < maxPage && maxPage > 0 &&
                    <div className="tabRightArrow" onClick={() => goRight()}>
                        <img src={rightTabArrow} alt="arrow"/>
                    </div>
                }

            </>
        );
    }
;

export default FormDesktopTab;
