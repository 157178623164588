import React, {useContext, useEffect, useReducer} from "react";

import ViewContext from "../../../contexts/ViewContext";
import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";

import StepOneValidationManager from "../../../managers/ValidationManagers/StepOneValidationManager";
import StepTwoValidationManager from "../../../managers/ValidationManagers/StepTwoValidationManager";
import StepThreeValidationManager from "../../../managers/ValidationManagers/StepThreeValidationManager";
import {validationStatusTypes} from "../../../managers/ValidationManagers/constants";

import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";

import QuotesList from "./QuotesList";
import VehiclesList from "./VehiclesList";
import EditQuote from "./EditQuote";
import BestOffer from "./BestOffer";
import SplashScreen from "./SplashScreen";
import {QuoteDataManager} from "../../../managers/DataManagers/QuoteDataManager";

const FormFor = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 4});

    const {viewSize, viewPage} = useContext(ViewContext);
    const {quoteData} = useContext(QuoteDataContext);

    const {stepOneStatus, stepOneValidation} = StepOneValidationManager();
    const {stepTwoStatus, stepTwoValidation} = StepTwoValidationManager();
    const {stepThreeStatus, stepThreeValidation} = StepThreeValidationManager();

    const {postRequestQuote} = QuoteDataManager();

    useEffect(() => {
        if (!renderState.isRender && !renderState.isFirst) {
            switch (renderState.requestCount) {
                case 4:
                    if (viewPage.quoteRequest) {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 3});
                    } else {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                    }
                    break;
                case 3:
                    postRequestQuote(true, false, true, false);
                    updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 2});
                    break;
                case 2:
                    if (!viewPage.quoteRequest) {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                    }
                    break;
                case 1:
                    updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
                    break;
                default:
                    break;
            }

        }
    }, [renderState, quoteData]);

    useEffect(() => {
        if (renderState.isFirst) {
            if (stepOneStatus === validationStatusTypes.Waiting) {
                stepOneValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Waiting) {
                stepTwoValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated && stepThreeStatus === validationStatusTypes.Waiting) {
                stepThreeValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated && stepThreeStatus === validationStatusTypes.Validated) {
                updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            }
        }
    });

    return (
        <div className="add-vehicles-content-wrapper">
            {
                renderState.isRender &&
                <div className="addVehiclesFormContentWrapper">
                    <div className="quotesContentWrapper">
                        {
                            viewSize.isMobile &&
                            <>
                                {
                                    !quoteData.showCallMessage &&
                                    <BestOffer/>
                                }
                                {
                                    quoteData.showCallMessage &&
                                    <SplashScreen/>
                                }
                            </>
                        }
                        <div>
                            <EditQuote/>
                            <VehiclesList/>
                        </div>

                        {
                            !viewSize.isMobile && quoteData.showCallMessage &&
                            <SplashScreen/>
                        }
                        {
                            !quoteData.showCallMessage &&
                            <QuotesList/>
                        }
                    </div>
                </div>
            }
        </div>
    );
}
export default FormFor;
