import {useContext, useReducer} from "react";
import {validationManagerReducer} from "./validationManagerReducer";
import {validationManagerActionTypes, validationManagerState, validationStatusTypes} from "./constants";
import GlobalDataContext from "../../contexts/GlobalDataContext";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";
import ViewContext from "../../contexts/ViewContext";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";

function checkEmail(value){
    if(value){
        return value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    return false;
}

function checkLicenceNumber(value){
    if(value){
        return value.match(/^\w{5}(?:-\w{5}){2}$/);
    }
    return false;
}

function checkMobile(value){
    if(value){
        return value.match(/^\d{10}$/);
    }
    else{
        return false;
    }
}

const StepThreeValidationManager = () => {

    const [localState, updateLocalState] = useReducer(validationManagerReducer, validationManagerState);

    const {globalData, updateGlobalData} = useContext(GlobalDataContext);
    const {discountData} = useContext(DiscountDataContext);

    const {updateViewRedirect, updateViewModal} = useContext(ViewContext);


    const localValidationPartOne = () => {
        if(discountData.email_to) {
            return checkEmail(discountData.email_to);
        }
        else{
            return false;
        }
    };

    const localValidationPartTwo = () => {
        let validationError = '';
        if(discountData) {
            if(discountData.quoter_info){
                if(!checkMobile(discountData.quoter_info.phone)){
                    validationError = 'Please enter your phone number';
                }
                if(!discountData.quoter_info.last_name){
                    validationError = 'Please enter your last name';
                }
                if(!discountData.quoter_info.first_name){
                    validationError = 'Please enter your first name';
                }
                if(discountData.email_to){
                    if(!checkEmail(discountData.email_to)){
                        validationError = 'Please enter a valid email';
                    }
                }
                else{
                    validationError = 'Please enter your email';
                }
                if(discountData.quoter_info.driver_license.length>0){
                    if(!checkLicenceNumber(discountData.quoter_info.driver_license)){
                        validationError = 'Please enter a valid drivers licence number';
                    }
                }
                if(discountData.isAlert){
                    if(!discountData.alert.current_company){
                        validationError = 'Please enter your current company';
                    }
                    if(!discountData.alert.current_price_monthly){
                        validationError = 'Please enter your current monthly price';
                    }
                    if(!globalData.isAuth){

                    }
                }
            }
            else{
                validationError = 'Please enter your first name';
            }
        }
        return validationError;
    };

    const localValidation = () => {

        updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.InProcess});

        let allErrors = [];

        const validPartOne = localValidationPartOne();

        if(!validPartOne){
            allErrors.push('Please enter your email');
        }

        const validPartTwo = localValidationPartTwo();

        if(validPartTwo!=='') {
            allErrors.push(validPartTwo);
        }

        if (allErrors.length > 0) {
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: allErrors[0],
                description: '',
                submitPath: ''
            });
            if(validPartOne){
                updateGlobalData({type:globalDataActionTypes.UpdateSignedInRedirect,payload:'thirdFormSecondPart'});
            }
            updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/info'});
            updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.Rejected});
        } else {
            updateLocalState({type: validationManagerActionTypes.UpdateValid, payload: true});
            updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.Validated});
        }
    }

    return {
        stepThreeIsValid: localState.isValid,
        stepThreeStatus: localState.status,
        stepThreeValidationPartOne: localValidationPartOne,
        stepThreeValidationPartTwo: localValidationPartTwo,
        stepThreeValidation: localValidation
    }
}

export default StepThreeValidationManager;
