import React, {useContext, useEffect} from "react";
import ViewContext from "../../../contexts/ViewContext";
import {viewModalActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import GlobalDataContext from "../../../contexts/GlobalDataContext";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";
import {globalDataActionTypes} from "../../../contexts/GlobalDataContext/globalDataActions";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import {quoteDataActionTypes} from "../../../contexts/formContexts/QuoteDataContext/quoteDataActions";

const ButtonFormClear = () => {
    const {globalData} = useContext(GlobalDataContext);
    const {updateViewModal} = useContext(ViewContext);

    const {updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);

    const {updateGlobalData} = useContext(GlobalDataContext);
    const {updateDriverData, updateDriverTab} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);
    const {updateQuoteData} = useContext(QuoteDataContext);

    const clearAllForms = () => {
        updateGlobalData({type: globalDataActionTypes.ClearUser});
        updateVehicleData({type:vehicleDataActionTypes.Clear});
        updateVehicleTab({type:vehicleDataActionTypes.Clear});
        updateDriverTab({type:driverDataActionTypes.Clear});
        updateDriverData({type:driverDataActionTypes.Clear});
        updateDiscountData({type:discountDataActionTypes.Clear});
        updateQuoteData({type:quoteDataActionTypes.ClearQuote});
        updateGlobalData({type: globalDataActionTypes.ClearPostal});
    }

    const submit = () => {
        clearAllForms();
        localStorage.clear();
    }

    useEffect(()=>{
        if(!globalData.isPostal){
            updateViewModal({type:viewModalActionTypes.OpenPostalModal});
        }
    },[globalData.isPostal])

    return(
        <button
            className="clearFormBtn"
            onClick={()=>{
                updateViewModal({type:viewModalActionTypes.OpenMessageRedirectModal,
                    title:"Are you sure you  want to clear the form?  This will erase all form data you have input.",
                    description: '',
                    submit,
                    // submitPath: '/vehicle/form/clear'
                });
            }}
        >
            Clear Form
        </button>
    );
}

export default ButtonFormClear;
