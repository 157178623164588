import React, {useContext} from "react";
import {Link} from "react-router-dom";

import GlobalDataContext from "../../../../contexts/GlobalDataContext";
import ViewContext from "../../../../contexts/ViewContext";

import {vehicleSteps} from "./constants";
import FormControlButtons from "./FormControlButtons";

const VehicleDesktopHeader = () => {

    const {globalData} = useContext(GlobalDataContext);

    const {viewHeader, viewSize} = useContext(ViewContext);

    return (
        <>
            <div className="addVehiclesFormNavBar">
                <div className="addVehiclesFormNavBarContent">
                    <div className="multiStepContentWrapper">
                        <div className="postalCodeData">
                            <h2>Car Insurance Quote</h2>
                            {
                                globalData.isPostal &&
                                <p>Your Postal Code: {globalData.postal.postal_code}</p>
                            }
                        </div>
                        <ol className='progtrckr'>
                            {
                                vehicleSteps.map((s, i) => (
                                  <Link to={vehicleSteps[i].path} key={i}
                                        className={`progtrckr-${
                                          viewHeader.vehicleId <= i ?
                                            viewHeader.vehicleId === i ?
                                              'doing' :
                                              'todo'
                                            : 'done'
                                        }`}>
                                    <li
                                      className={`progtrckr-${
                                        viewHeader.vehicleId <= i ?
                                          viewHeader.vehicleId === i ?
                                            'doing' :
                                            'todo'
                                          : 'done'
                                      }`}
                                        key={i}
                                        value={i}
                                    >

                                            <img className='multiStepIcon' src={vehicleSteps[i].icon}
                                                 alt={vehicleSteps[i].icon}/>
                                            <div>
                                                <span>{viewSize.isMobile ? `${vehicleSteps[i].name} / 4` : vehicleSteps[i].name}</span>
                                                <span className='multiStepTitle'>{vehicleSteps[i].title}</span>
                                            </div>
                                    </li>
                                  </Link>
                                ))
                            }
                        </ol>

                        <FormControlButtons />

                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleDesktopHeader;
