import moment from "moment";

export const defaultBrokerPhone = '1-855-843-1570';

export const formGenderTypes = [
    {
        name: 'Male',
        id: 0,
        value: 'Male'
    },
    {
        name: 'Female',
        id: 1,
        value: 'Female'
    },
    {
        name: 'Other',
        id: 2,
        value: 'Other'
    }
];

export const formOccupationTypes = [
    {
        id: 0,
        name: 'Employed',
        value: 'Employed'
    },
    {
        id: 1,
        name: 'Unemployed',
        value: 'Unemployed'
    }
];

export const formMaritalTypes = [
    {
        id: 0,
        name: 'Single',
        value: 'S'
    },
    {
        id: 1,
        name: 'Married',
        value: 'M'
    }
];

export const formLicenceTypes = {
    g: 'G',
    gOne: 'G1',
    gTwo: 'G2'
};

export const formRelationshipTypes = [
    {name:'Spouse', value:'Spouse'},
    {name:'Co-habitant', value:'Co-habitant'},
    {name:'Child', value:'Child'},
    {name:'Parent', value:'Parent'},
    {name:'Sibling', value:'Sibling'},
    {name:'Relative', value:'Relative'},
    {name:'Employee', value:'Employee'},
    {name:'Insured', value:'Insured'},
    {name:'Lessee', value:'Lessee'},
    {name:'Third Party', value:'Third Party'},
    {name:'Other', value:'Other'}
];

export const formGStartDate = moment(new Date("01 April 1994")).local();

export const formOptionsInsuranceCancellation = [
    {name:'Select from the List',value:''}
    ,{name:'Cancelled for non-payment',value:'NonPay'}
    ,{name:'Cancelled for other reason',value:'OtherLapse'}
    ,{name:'Driving without insurance',value:'DWOI'}
    ,{name:'No car/no insurance needed',value:'NotReqrd'}
    ,{name:'Cancelled for material misrepresentation',value:'MatMisrep'}
];
export const formOptionsLicenseSuspensions = [
    {name:'Select from the List',value:''}
    ,{name:'Alcohol related licence suspension',value:'Alcohol'}
    ,{name:'Criminal related (Non-Alcohol) Licence Suspension',value:'Crim'}
    ,{name:'Too many convictions',value:'Points'}
    ,{name:'Suspension due to non payment of fines',value:'Fine'}
    ,{name:'Other - Administrative',value:'OtherSusp'}
    ,{name:'Other - Non-administrative',value:'OtherNonAdmin'}
];
export const formOptionsGroupTrafficTickets = [
    {
        options:[{name:'Select from the List',value:''}],
    },
    {
        label:'Commonly',
        options:[
            {value:'SP',name:'Speeding'},
            {value:'SS',name:'Stop sign'},
            {value:'TSL',name:'Traffic light'},
            {value:'CPV',name:'Prohibited use of hand-held device'},
            {value:'IT',name:'Improper turn'}
        ]
    },
    {
        label:'Minor',
        options:[
            {value:'DB',name:'Defective brakes'},
            {value:'LIC',name:'Drivers licence violations'},
            {value:'FCIC',name:'Failure to carry or have insurance card'},
            {value:'FPEI',name:'Failure to produce evidence of insurance'},
            {value:'FTS',name:'Failing to signal'},
            {value:'SB',name:'Failure to use seatbelts'},
            {value:'FTY',name:'Failing to yield'},
            {value:'PX',name:'Failing to yield to pedestrian'},
            {value:'FTC',name:'Following too closely'},
            {value:'HL',name:'Headlight offenses'},
            {value:'ILC',name:'Improper lane change'},
            {value:'IP',name:'Improper passing'},
            {value:'IT',name:'Improper turn'},
            {value:'DH',name:'Improper use of divided highway'},
            {value:'MINOR',name:'Other minor conviction (not specified)'},
            {value:'OT',name:'Obstructing traffic'},
            {value:'CPV',name:'Prohibited use of hand-held device'},
            {value:'SP',name:'Speeding'},
            {value:'SS',name:'Stop sign'},
            {value:'TSL',name:'Traffic light'},
            {value:'UM',name:'Unsafe move'},
            {value:'UV',name:'Unsafe vehicle'},
            {value:'OW',name:'Wrong way on one way'},
            {value:'DRD',name:'Distracted driving'}
        ]
    },
    {
        label:'Major',
        options:[
            {value:'FDH',name:'Failure to report damage to highway property'},
            {value:'FRA',name:'Failure to report accident'},
            {value:'PSB',name:'Improper passing of a school bus'},
            {value:'PSG',name:'Improper passing/speeding in a school or playground zone'},
            {value:'OMVNI',name:'Operator motor vehicle - no insurance'},
            {value:'MAJOR',name:'Other major conviction (not specified)'},
            {value:'STN',name:'Stunting'}
        ]
    },
    {
        label:'Serious',
        options:[
            {value:'ALC',name:'Class G1/G2 driver with alcohol in blood'},
            {value:'RB',name:'Class G1/G2 refuse breath sample'},
            {value:'CN',name:'Criminal negligence'},
            {value:'DD',name:'Dangerous driving'},
            {value:'DUS',name:'Driving while licence under suspension'},
            {value:'FTR',name:'Failure to stop/remain at the scene of an accident'},
            {value:'IMP',name:'Impaired driving'},
            {value:'MM',name:'Manslaughter committed in the operation or use of a motor vehicle'},
            {value:'SECTD',name:'Other serious conviction (not specified)'},
            {value:'CD',name:'Careless driving, undue care or attention'},
            {value:'RAC',name:'Racing'},
        ]
    }
];

