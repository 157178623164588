import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import VehiclePeriodInput from "../../../../../../components/ui/vehicleFormInputs/VehiclePeriodInput";

const DriverInsuredDate = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const handleInput = useCallback((value)=>{
        updateDriverData({type: driverDataActionTypes.UpdateInsuredYear, index: driverTab.activeItem, payload: value});
    },[]);

    const handleCheckBox = useCallback((evt)=>{
        evt.persist();
        updateDriverData({type: driverDataActionTypes.UpdateInsured, index: driverTab.activeItem, payload: evt.target.value === 'on'});
    },[]);



    return(
        <>
            {
                driverData.items[driverTab.activeItem].listed &&
                <>
                    <VehicleInputTitleWithToolTip
                        id="currentInsuranceDate"
                        extraClassName="nextToCalendarInput"
                        // title={`When did ${driverTab.tabs[driverTab.activeItem].name}  first sign up with \n current insurance company?`}
                        title={`How long has ${driverTab.tabs[driverTab.activeItem].name} been with their current insurance provider?`}
                        description="It is common for insurers to provide loyalty rewards or discounts for valued customers. Loyalty is a positive trait in the industry, and most insurance companies will want to provide some incentive for continued customer relationships through tangible policy rewards."
                    />
                    <VehiclePeriodInput
                        className='inputDate datePeriod'
                        name='insured_year'
                        defaultValue={driverData.items[driverTab.activeItem].insured_year}
                        listedYear={driverData.items[driverTab.activeItem].listed_year}
                        parentName=''
                        handleDate={handleInput}
                        disabled={!driverData.items[driverTab.activeItem].insured}
                    />
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            checked={!driverData.items[driverTab.activeItem].insured}
                            value={driverData.items[driverTab.activeItem].insured ? 'off' : 'on'}
                            name="insured"
                            onChange={(event) => handleCheckBox(event)}
                        />
                        <label><span>Not currently insured</span></label>
                    </div>
                </>
            }
        </>
    );
}
export default DriverInsuredDate;
