import React, {useContext} from "react";

import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";
import {quoteDataActionTypes} from "../../../contexts/formContexts/QuoteDataContext/quoteDataActions";
import CallButton from "../../ui/buttons/CallButton";
import SpecialPhone from "./SpecialPhone";

const QuoteItemDescription = (props) => {

    const {quoteData, updateQuoteData} = useContext(QuoteDataContext);
    const {phoneString} = SpecialPhone(
        {index: props.item.index}
    );

    return(
        <>
            <div className="quotePhoneRow">
                <h3>Call now to get this rate</h3>
                {
                    quoteData.quotes[props.item.index].broker_profile.phone &&
                    <CallButton
                        phoneString={quoteData.quotes[props.item.index]?.broker_profile?.phone || phoneString}
                    />
                }
            </div>
            <div className="offersThirdRow">
                <button className="viewOffersDetails" onClick={() => {
                    updateQuoteData({type:quoteDataActionTypes.UpdateTab, index:props.item.index,payload:!props.item.isVisible});
                }}>
                    {
                        props.item.isVisible ? 'Hide Details' : 'View Details'
                    }
                </button>
            </div>
            {
                props.item.isVisible &&
                <div className="offersFourthRow">
                    <h3>Who is {quoteData.quotes[props.item.index].company.name} ?</h3>
                    <p>{quoteData.quotes[props.item.index].company.description}</p>
                </div>
            }
        </>
    )
};

export default QuoteItemDescription;
