import axios from "axios";
import {AUTH_API, responseCancelState, responseNetworkErrorState, responseLogoutState} from "../config/constants";

import localStorageService from "./localStorageService";

const myLS = new localStorageService();

axios.interceptors.request.use(config => {
        if (myLS.getAccessToken() && myLS.getRefreshToken()) {
            config.headers['Authorization'] = `Bearer ${myLS.getAccessToken()}`;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

axios.interceptors.response.use((response) => {
    if (response.data.tokens) {
        myLS.setTokens(response.data.tokens);
    }
    return response;
}, function (error) {
    if (error.response) {
        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.url === AUTH_API.POST_TOKEN) {
            if(error.response.data.code==='token_not_valid'){
                myLS.clearTokens();
                return responseLogoutState;
            }
            else{
                return responseLogoutState;
            }
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = myLS.getRefreshToken();
            return axios.post(AUTH_API.POST_TOKEN,
                {
                    refresh: refreshToken
                })
                .then(res => {
                    if (res.status === 201 || res.status === 200) {
                        myLS.setTokens(res.data);
                        return axios(originalRequest);
                    }
                })
        }

        return Promise.reject(error);
    } else {
        if(axios.isCancel(error)){
            return responseCancelState;
        }
        else{
            return responseNetworkErrorState;
        }
    }

});
