import React, {useContext, useEffect, useState} from "react";
import EndorsementRow from "./EndorsementRow";
import VehicleDataContext from "../../../../contexts/formContexts/VehicleDataContext";

const EndorsementsRecalc = () => {

    const {vehicleData, vehicleTab} = useContext(VehicleDataContext);

    const [isCoverage, setIsCoverage] = useState(vehicleData.items[vehicleTab.activeItem].collision.coverage && vehicleData.items[vehicleTab.activeItem].comprehensive.coverage);

    useEffect(()=>{
            setIsCoverage(vehicleData.items[vehicleTab.activeItem].collision.coverage && vehicleData.items[vehicleTab.activeItem].comprehensive.coverage);
    },[vehicleTab.activeItem, vehicleData]);

    return(
      <div className='formEndorsementWrapper'>

          <h4>Endorsements</h4>
          <div className='formEndorsementRows'>
              <EndorsementRow isActive={isCoverage} title='Loss of Use' id={0} toolTip='This endorsement will offer you protection in the event you are without use of your vehicle from an accident or it is stolen. You will be provided with a rental or replacement car while your own vehicle is in the process of being repaired or replaced.'/>
              <EndorsementRow isActive={isCoverage} title='Liability for Damage for Non-owned Cars' id={1} toolTip={'This endorsement will provide coverage and insure you as a driver for vehicles you do not own, for up to $50,000 in physical damages, effectively known as the ‘rental car endorsement’. This would be applicable throughout all of Canada and the USA.'}/>
              <EndorsementRow isActive={true} title='Accident Waiver or Forgiveness' id={2} toolTip={'Available to only drivers who have clean record, this endorsement will effectively forgive you and protect your driving record if you were to have your first at-fault accident. This would be beneficial as it would mean that you monthly premiums would not increase if you were to have an at-fault accident.'}/>
          </div>
      </div>
    );
}

export default EndorsementsRecalc;
