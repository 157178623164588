import cloneDeep from 'lodash/cloneDeep';
import {discountDataActionTypes} from "./discountDataActionTypes";
import moment from "moment";
import {currentDate, dateFormatFullYear} from "../../../config/dateConfig";
import {defaultBrokerPhone} from "../../../config/formConstants";

export const discountQuoterInfoInitialState = {
    first_name: "",
    last_name: "",
    phone: "",
    original_postal_code: "",
    broker_info: {
        phone: defaultBrokerPhone
    },
    driver_license: '',
    splash_screen: false,
    casl_consent: false,
}

export const discountDataInitialState = {
    app_install_discount: false,
    caa_member_discount: false,
    email_to: "",
    multiple_policies_discount: true,
    policy_start: moment(currentDate).format(dateFormatFullYear),
    quoter_info: {...discountQuoterInfoInitialState},
    isAlert:false,
    alert:{
        current_company: '',
        current_price_monthly: ''
    },
    vehLink:[{
        driver_index:0,
        priority: 'Prn',
        vehicle_index: 0
    }],
    newUser:{
        first_name:'',
        last_name:'',
        dob:'',
        occupation: 'Employed',
        marital_status: 'S',
        gender:'Male',
        email:'',
        password1:'',
        password2:''
    }
}

function updateLocalDiscount(state) {
    localStorage.setItem('discountData', JSON.stringify(state));
}

function removeVehlink(state, id){
    let newState = {...state};
    let newVehlink = [...state.vehLink.filter(item=>item.vehicle_index!==id)];
    newState.vehLink =[];
    for(let zV=0;zV<newVehlink.length;zV++){
        newState.vehLink.push({...newVehlink[zV], vehicle_index: zV});
    }
    updateLocalDiscount(newState);
    return newState;
}

function addVehlink(state){
    let newState = {...state};
    newState.vehLink.push({
        driver_index:0,
        priority: 'Prn',
        vehicle_index: state.vehLink.length
    })
    updateLocalDiscount(newState);
    return newState;
}

function updateAndReturnDiscountState(state, updatedState){
    const newState = {...state, ...updatedState};
    updateLocalDiscount(newState);
    return newState;
}

function returnNewDiscount(state, name, value){
    const newState = {...state, [name]: value};
    updateLocalDiscount(newState);
    return newState;
}

function returnMultipleNewDiscount(state, names, values) {
    let newState = {...state};
    for(let z=0;z<names.length;z++){
        newState[names[z]] = values[z];
    }
    updateLocalDiscount(newState);
    return newState;
}

function clearDiscountState(){
    const newState = cloneDeep(discountDataInitialState);
    updateLocalDiscount(newState);
    return newState;
}


export const discountDataReducer = (state,action) => {

    switch (action.type) {

        case discountDataActionTypes.Clear:
            return clearDiscountState();

        case discountDataActionTypes.UpdatePolicyStart:
            return returnNewDiscount(state, 'policy_start', action.payload);

        case discountDataActionTypes.UpdateMultiplePolicies:
            return returnNewDiscount(state, 'multiple_policies_discount', action.payload);

        case discountDataActionTypes.UpdateCaaMember:
            return returnNewDiscount(state, 'caa_member_discount', action.payload);

        case discountDataActionTypes.UpdateAppInstallDiscount:
            return returnNewDiscount(state, 'app_install_discount', action.payload);

        case discountDataActionTypes.UpdateQuoterPostal:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, original_postal_code: action.payload});

        case discountDataActionTypes.UpdateEmail:
            return returnMultipleNewDiscount(state, ['email_to','newUser'], [ action.payload,{...state.newUser, email: action.payload}]);

        case discountDataActionTypes.UpdateQuoterFirstName:
            return returnMultipleNewDiscount(state, ['quoter_info','newUser'], [{...state.quoter_info, first_name: action.payload},{...state.newUser, first_name: action.payload}]);

        case discountDataActionTypes.UpdateQuoterLastName:
            return returnMultipleNewDiscount(state, ['quoter_info','newUser'], [{...state.quoter_info, last_name: action.payload},{...state.newUser, last_name: action.payload}]);

        case discountDataActionTypes.UpdateQuoterDriverLicence:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, driver_license: action.payload});

        case discountDataActionTypes.UpdateQuoterPhone:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, phone: action.payload});

        case discountDataActionTypes.UpdateQuoterBrokerInfo:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, broker_info: action.payload});

        case discountDataActionTypes.AddVehlink:
            return addVehlink(state);

        case discountDataActionTypes.RemoveVehlink:
            return removeVehlink(state, action.payload);

        case discountDataActionTypes.UpdateVehlinks:
            return  returnNewDiscount(state, 'vehLink', action.payload);

        case discountDataActionTypes.UpdateIsAlert:
            return returnNewDiscount(state, 'isAlert', action.payload);

        case discountDataActionTypes.UpdateAlertCompany:
            return returnNewDiscount(state, 'alert', {...state.alert, current_company: action.payload});

        case discountDataActionTypes.UpdateAlertPrice:
            return returnNewDiscount(state, 'alert', {...state.alert, current_price_monthly: action.payload});

        case discountDataActionTypes.UpdateUserDob:
            return returnNewDiscount(state, 'newUser', {...state.newUser, dob: action.payload});

        case discountDataActionTypes.UpdateUserGender:
            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                gender: action.payload==='M' ? 'Male' : action.payload==='F' ? 'Female' : 'Other'
            });

        case discountDataActionTypes.UpdateUserOccupation:
            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                occupation: action.payload === 998 ? "Employed" : "Unemployed"
            });

        case discountDataActionTypes.UpdateUserMarital:
            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                marital_status: action.payload
            });


        case discountDataActionTypes.UpdateUserPassword:
            return returnNewDiscount(state, 'newUser', {...state.newUser, password1: action.payload});

        case discountDataActionTypes.UpdateConfirmPassword:
            return returnNewDiscount(state, 'newUser', {...state.newUser, password2: action.payload});

        case discountDataActionTypes.UpdateProvinceName:
            return updateAndReturnDiscountState({...state, province_name: action.payload});

        case discountDataActionTypes.UpdateProvinceCode:
            return updateAndReturnDiscountState({...state, province_code: action.payload});


        case discountDataActionTypes.UpdateDiscountData:
            return updateAndReturnDiscountState(state, action.payload);

        default:
            return state;

    }
}
