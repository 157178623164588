import {globalDataActionTypes} from "./globalDataActions";
import {globalDataInitialState} from './GlobalDataContext';
import {clearLocalPostalGlobalState, getLocalPostalGlobalState, setLocalPostalGlobalState} from "./helper";
import {postalCodeFormat} from "../../helpers/postalCodeFormat";

const updateUser = (data, oldData) => {
    return {
        id: data.id ? data.id : oldData.id,
        email: data.email ? data.email : oldData.email,
        first_name: data.first_name ? data.first_name : oldData.first_name,
        last_name: data.last_name ? data.last_name : oldData.last_name
    }
};

export const globalDataReducer = (state, action) => {

    switch (action.type) {

        case globalDataActionTypes.UPDATE_UID:
            return {...state, uid: action.uid};

        case globalDataActionTypes.UpdateAuth:
            return {...state, isAuth: action.isAuth};

        case globalDataActionTypes.UpdateSignedInRedirect:
            return {...state, signedInRedirect: action.payload};

        case globalDataActionTypes.UpdateUser:
            return {...state, ...updateUser(action.data, state)};

        case globalDataActionTypes.UpdatePostal:
            if (action.payload) {
                if (action.payload.city && action.payload.postal_code && action.payload.location_index) {
                    setLocalPostalGlobalState({
                        city: action.payload.city,
                        postal_code: postalCodeFormat(action.payload.postal_code),
                        location_index: action.payload.location_index,
                        province_name: action.payload.province_name,
                        province_code: action.payload.province_code,
                    });

                    return {
                        ...state,
                        isPostal: true,
                        postal: {
                            city: action.payload.city,
                            postal_code: postalCodeFormat(action.payload.postal_code),
                            location_index: action.payload.location_index,
                            province_name: action.payload.province_name,
                            province_code: action.payload.province_code
                        }
                    }
                }
            }
            return state;

        case globalDataActionTypes.ClearPostal:
            clearLocalPostalGlobalState();
            return {...state, isPostal: false, postal: {}};

        case globalDataActionTypes.GetLocalPostal:
            return {...state, ...getLocalPostalGlobalState()};

        case globalDataActionTypes.ClearUser:
            return globalDataInitialState;

        default:
            return state;
    }
};
