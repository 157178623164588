import React, {useContext} from "react";
import ButtonFormClear from "../../../ui/header/ButtonFormClear";
import LeftArrow from "../../../../resources/images/left-arrow-gray.svg";
import RightArrow from "../../../../resources/images/right-arrow.svg";
import ViewContext from "../../../../contexts/ViewContext";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import {vehicleSteps} from "./constants";

const FormControlButtons = () => {

    const {viewHeader, updateViewRedirect, updateViewModal} = useContext(ViewContext);

    function previous() {
        if (viewHeader.vehicleId > 0) {
            updateViewRedirect({
                type: viewRedirectActionTypes.Redirect,
                payload: vehicleSteps[(viewHeader.vehicleId - 1)].path
            })
        }
    }

    function next() {
        if (viewHeader.vehicleId < vehicleSteps.length) {
            updateViewRedirect({
                type: viewRedirectActionTypes.Redirect,
                payload: vehicleSteps[(viewHeader.vehicleId + 1)].path
            })
        }
    }

    return(
        <div className="btns">
            {
                viewHeader.vehicleId === 0 &&
                <ButtonFormClear />

            }
            {
                viewHeader.vehicleId > 0 &&
                <button
                    style={viewHeader.vehicleId > 0 ? {} : {display: 'none'}}
                    className={viewHeader.vehicleId === 3 ? 'last-prev-btn' : 'prev-btn'}
                    onClick={previous}
                >
                    <img src={LeftArrow} alt="arrow"/>
                </button>
            }
            {
                viewHeader.vehicleId < 3 &&

                <button
                    style={viewHeader.vehicleId < 3 ? {} : {display: 'none'}}
                    className="next-btn"
                    onClick={next}
                >
                    <img src={RightArrow} alt="arrow"/>
                </button>
            }
            {
                /* finish button return
                viewHeader.vehicleId === 3 &&

                <button
                    style={viewHeader.vehicleId === 3 ? {} : {display: 'none'}}
                    className="finish-btn" onClick={finishQuotes}
                >
                    Finish
                </button>
                 */
            }
        </div>
    );
}

export default FormControlButtons;
