import React, {useEffect, useState} from "react";

function returnValue(str) {
    return str ? str.replace(/ /g, "").replace(/-/g, "").replace(/.{5}(?=.)/g, '$&-') : '';
}

const InputLicence = React.memo((props) => {

    const [inputValue, setInputValue] = useState(returnValue(props.defaultValue, props.maxLength));

    useEffect(() => {
        props.onChange({target: {value: inputValue, name: props.name}}, props.rootName);
    }, [inputValue]);

    const handleChange = (event) => {
        setInputValue(returnValue(event.target.value, props.maxLength));
    }

    return (
        <input
            placeholder={props.placeholder}
            name={props.name}
            className={props.classNames}
            value={inputValue}
            maxLength={props.maxLength}
            onChange={handleChange}
            onBlur={handleChange}
        />
    );
});

export default InputLicence;
