import React, {useContext, useEffect, useReducer} from "react";
import {useHistory, useLocation,} from "react-router-dom";
import queryJson from "../helpers/queryJson";
import DiscountDataContext, {discountDataActionTypes} from "../contexts/formContexts/DiscountDataContext";
import PostalDataManager from "../managers/DataManagers/PostalDataManager";
import {requestStatusTypes} from "../config/constants";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";

const BrokerKeys = () => {

    let history = useHistory();
    const queryRoot = useLocation().search.split('?');
    const {updateDiscountData} = useContext(DiscountDataContext);
    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);
    const {getPostal, postalRequestState} = PostalDataManager();

    useEffect(() => {
        if (!renderState.isFirst) {
            if (postalRequestState !== requestStatusTypes.Loading && postalRequestState !== requestStatusTypes.Waiting) {
                history.push('/');
            }
        }

    }, [renderState, postalRequestState])

    useEffect(() => {
        if (renderState.isFirst) {
            if (queryRoot.length > 1) {
                const query = queryJson(queryRoot);
                updateDiscountData({type: discountDataActionTypes.UpdateQuoterBrokerInfo, payload: query});
                if (query.province_name) {
                    updateDiscountData({
                        type: discountDataActionTypes.UpdateProvinceName,
                        payload: query.province_name
                    });
                }
                if (query.province_code) {
                    updateDiscountData({
                        type: discountDataActionTypes.UpdateProvinceCode,
                        payload: query.province_code
                    });
                }
                if (query.postal_code) {
                    getPostal(query.postal_code, false);
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false})
        }
    }, [queryRoot]);

    return (
        <>
        </>
    );
}
export default BrokerKeys;
