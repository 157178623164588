import React, {useEffect, useState} from "react";
import {formatPhoneObject} from "../../../helpers/formatUtils";

const CallButton = (props) => {

    const [phoneObject, setPhoneObject] = useState({});

    useEffect(()=>{
        setPhoneObject(formatPhoneObject(props.phoneString));
    },[props.phoneString]);

    return(
        <a target="_blank" rel="noreferrer noopener" href={`tel:${phoneObject.phoneNumber}`} className="phoneNumber">{phoneObject.phoneTitle}</a>
    );
};

export default CallButton;
