import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';

import {AuthDataManager} from "../../../managers/DataManagers";
import UseForm from "../../../helpers/UseForm";

import {Initial_SignInFormData} from "./constants";
import ButtonSubmit from "../../ui/buttons/ButtonSubmit";
import InputWrapper from "../../ui/Inputs/InputWrapper";
import ViewContext from "../../../contexts/ViewContext/ViewContext";


const SignInForm = () => {

    const{viewPage} = useContext(ViewContext);

    const [formData, updateFormData] = useState(cloneDeep(Initial_SignInFormData));

    const {signIn, authRequestState, authRequestError, authSuccessMessage} = AuthDataManager();

    const {inputHandler, inputBlur, submitHandler, disableFormInput, disableSubmitButton} = UseForm({
        submitHandler: signIn,
        requestState: authRequestState,
        formData: formData,
        updateFormData: updateFormData
    });

    return (
        <div className='addVehiclesFormContentWrapper'>
            <div className='signUpWrapper formWrapper'>

                <h4 className='signUpTitle'>Log In</h4>
                {
                    viewPage.message &&
                    <h5>{viewPage.message}</h5>
                }

                <form>

                    {
                        formData.map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    inputBlur={inputBlur}
                                />
                            )
                        })
                    }

                    {
                        authRequestError &&
                        <span className='errorMsg'>{authRequestError}</span>
                    }

                    <ButtonSubmit
                        classNames="authSubmitBtn"
                        title="Login"
                        requestState={authRequestState}
                        SuccessMessage={authSuccessMessage}
                        disabled={disableSubmitButton}
                        submitHandler={submitHandler}
                    />

                    <p>Don`t have an account? <Link className='signUpLogin' to="/accounts/signup">Sign up</Link></p>

                </form>

            </div>
        </div>
    );
};

export default SignInForm;
