import React, {useContext, useEffect, useReducer} from "react";
import {BrowserRouter as Router} from 'react-router-dom';

import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";

import AppResize from './AppResize';

import {ContentRedirect, RouterListener} from "./AppHelper";

import ContentLoader from "../components/layout/ConetntLodaer/ContentLoader";
import ModalWrapper from "../components/layout/modals/ModalWrapper";


import GlobalDataContext from "../contexts/GlobalDataContext";
import ViewContext from "../contexts/ViewContext";
import {viewLoaderActionTypes} from "../contexts/ViewContext/viewContextActions";


import {VehicleDataContextProvider} from "../contexts/formContexts/VehicleDataContext";
import {DriverDataContextProvider} from "../contexts/formContexts/DriverDataContext";
import {DiscountDataContextProvider} from "../contexts/formContexts/DiscountDataContext";


import {AuthDataManager} from "../managers/DataManagers";

import {requestStatusTypes} from "../config/constants";
import localStorageService from "../services/localStorageService";
import AppHeader from "../components/layout/headers/AppHeader";
import VehicleHeader from "../components/layout/headers/VehicleHeader";
import AppRoutes from "./AppRoutes";
import {QuoteDataContextProvider} from "../contexts/formContexts/QuoteDataContext";
import PostalDataManager from "../managers/DataManagers/PostalDataManager";
import {FormConfigStateProvider} from "../contexts/formContexts/FormConfigState";


const AppWrapper = () => {

    const {globalData} = useContext(GlobalDataContext);

    const {viewLoader, viewSize, viewHeader, updateViewLoader} = useContext(ViewContext);

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount:2});

    const lS = new localStorageService();

    const {refreshSignIn, authRequestState, authRequestError} = AuthDataManager();

    const {postalRequestState, getPostal} = PostalDataManager();


    useEffect(()=>{
        if(!renderState.isRender && !renderState.isFirst){
            if(renderState.requestCount===2){
                if(authRequestState!==requestStatusTypes.Waiting && authRequestError!==requestStatusTypes.Loading){
                    updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:1});
                }

            }
            if(renderState.requestCount===1){
                if(globalData.isPostal){
                    if(postalRequestState===requestStatusTypes.Waiting){
                        getPostal(globalData.postal.postal_code, false, globalData.postal.postal_code);
                    }
                    if(postalRequestState!==requestStatusTypes.Waiting && postalRequestState!==requestStatusTypes.Loading){
                        updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:0});
                    }
                }
                else{
                    updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:0});
                }
            }
            if(renderState.requestCount===0){
                updateRenderState({type:renderStateActionTypes.UpdateRender,payload:true});
                updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
            }
        }

    }, [renderState,authRequestState, postalRequestState])

    useEffect(() => {
        if (renderState.isFirst) {
            if (lS.getRefreshToken()) {
                refreshSignIn();
            } else {
                updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:1});
            }
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    });


    return (
        <div className="app-wrapper">
            <Router>
                <ModalWrapper/>
                <AppResize/>
                {
                    viewLoader.isOpen &&
                    <ContentLoader/>
                }
                {
                    renderState.isRender &&
                    <>

                        <RouterListener/>
                        <ContentRedirect/>

                        <AppHeader/>

                        <div className={`contentWrapper ${
                            viewHeader.vehicleNavigation ? viewSize.isMobile ? 'contentWrapperVehicleMobile' : 'contentWrapperVehicle' : ''}`
                        }>
                            <FormConfigStateProvider>
                                <VehicleDataContextProvider>
                                    <DriverDataContextProvider>
                                        <DiscountDataContextProvider>
                                            <QuoteDataContextProvider>
                                                {
                                                    viewHeader.vehicleNavigation &&
                                                    <VehicleHeader/>
                                                }

                                                <AppRoutes/>

                                            </QuoteDataContextProvider>
                                        </DiscountDataContextProvider>
                                    </DriverDataContextProvider>
                                </VehicleDataContextProvider>
                            </FormConfigStateProvider>

                        </div>
                    </>
                }
            </Router>
        </div>
    );
};

export default AppWrapper;
