import {useContext, useEffect, useState} from "react";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";

const SpecialPhone = (props) => {
    const { index = -1, } = props;

    const [phoneString, setPhoneString] = useState('');
    const {quoteData} = useContext(QuoteDataContext);
    const {discountData} = useContext(DiscountDataContext);

    useEffect(() => {
        if (index > -1) {
            if (quoteData.quotes[index].broker_profile) {
                setPhoneString(quoteData.quotes[index].broker_profile.phone);
            }
            else {
                if (discountData.quoter_info.broker_info.phone) {
                    setPhoneString(discountData.quoter_info.broker_info.phone);
                }
            }
        }
        else {
            if (discountData.quoter_info.broker_info.phone) {
                setPhoneString(discountData.quoter_info.broker_info.phone);
            }
        }
    }, [quoteData.quotes, discountData.quoter_info.broker_info, index]);

    return{
        phoneString: phoneString
    }
}

export default SpecialPhone;
