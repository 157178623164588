import React, {useContext, useEffect, useReducer} from "react";
import ReactTooltip from "react-tooltip";
import {QuoteDataManager} from "../../../managers/DataManagers/QuoteDataManager";
import {requestStatusTypes} from "../../../config/constants";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {formStateActionTypes, formStateReducer} from "../../../helpers/formState";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import EndorsementsRecalc from "./Endorsements";

const VehicleRecalc = () => {

    const {quoteErrorMessage, quoteRequestState, postRequestQuote} = QuoteDataManager();
    const  {vehicleTab,vehicleData, updateVehicleData} = useContext(VehicleDataContext);

    const [localActive, updateLocalActive] = useReducer(formStateReducer, {});
    const [localState, updateLocalState] = useReducer(formStateReducer, {});
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount:2});


    function onSelectChange(event, parent){
        updateLocalState({type:formStateActionTypes.Update,payload:{
            ...localState,
            [parent]:{
                ...localState[parent],
                [event.target.name]:event.target.value
            }
        }})
    }

    useEffect(()=>{

        if(!renderState.isFirst && !renderState.isRender){
            if(renderState.requestCount===2){
                if(localActive.activeItem>-1){
                    updateLocalState({type:formStateActionTypes.Update, payload:{...vehicleData.items[localActive.activeItem]}});
                    updateRenderState({type:renderStateActionTypes.UpdateRequest,payload:1});
                }
            }
            if(renderState.requestCount===1){
                if(localState.comprehensive){
                    updateRenderState({type:renderStateActionTypes.UpdateRequest,payload:0});
                }
            }
            if(renderState.requestCount===0){
                updateRenderState({type:renderStateActionTypes.UpdateRender,payload:true});
            }
        }
        else{
            if(renderState.isRender){
                if(localState.comprehensive.deductible!==vehicleData.items[localActive.activeItem].comprehensive.deductible){
                    updateVehicleData({type:vehicleDataActionTypes.UpdateComprehensiveValue,index:localActive.activeItem,payload:localState.comprehensive.deductible});
                }
                if(localState.collision.deductible!==vehicleData.items[localActive.activeItem].collision.deductible){
                    updateVehicleData({type:vehicleDataActionTypes.UpdateCollisionValue,index:localActive.activeItem,payload:localState.collision.deductible});
                }
                if(localState.liability.limit!==vehicleData.items[localActive.activeItem].liability.limit){
                    updateVehicleData({type:vehicleDataActionTypes.UpdateLiability,index:localActive.activeItem,payload:localState.liability.limit});
                }
            }
        }

    }, [localState, renderState, localActive])

    useEffect(()=>{
        if(quoteRequestState!==requestStatusTypes.Loading && quoteRequestState!==requestStatusTypes.Waiting){
            window.scrollTo(0,0);
        }
    },[quoteRequestState]);

    useEffect(()=>{
        updateLocalState({type:formStateActionTypes.Update, payload:{}});
        updateRenderState({type:renderStateActionTypes.Clear,payload:{...renderInitialState, requestCount:2}});
    },[vehicleTab.activeItem]);

    useEffect(()=>{
        if(renderState.isFirst){
            updateLocalActive({type:formStateActionTypes.Update,payload: {activeItem: vehicleTab.activeItem}})
            updateRenderState({type:renderStateActionTypes.UpdateFirst,payload:false});
        }
    });

    return(
        <div className="formWrapper">
            {
                renderState.isRender &&
                    <>

                    <div className="formLabelAndTooltipWrapper">
                        <h4>Comprehensive coverage</h4>
                        <div className="tooltipIcon" data-tip={true} data-for='comprehensiveCoverage'/>
                        <ReactTooltip id='comprehensiveCoverage' className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">This deductible is the amount of money that you will pay out of your own pocket if your vehicle is damaged in events not covered by collision coverage. This includes events such as falling or flying objects, vandalism, and theft. Your insurance company covers the cost of repair minus the deductible chosen.</p>
                        </ReactTooltip>
                    </div>

                        <select
                            defaultValue={localState.comprehensive.deductible}
                            name='deductible'
                            className='formSelect'
                            onChange={(event)=>{onSelectChange(event,'comprehensive')}}
                        >
                            <option value="" disabled={true}>Select from the list</option>
                            <option value="0">No Coverage</option>
                            <option value="500">$500 Deductible</option>
                            <option value="1000">$1,000 Deductible</option>
                            <option value="1500">$1,500 Deductible</option>
                        </select>

                    <div className="formLabelAndTooltipWrapper">
                        <h4>Collision coverage</h4>
                        <div className="tooltipIcon" data-tip={true} data-for='collisionCoverage'/>
                        <ReactTooltip id='collisionCoverage' className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">This deductible is the specific dollar amount you will pay out of your own pocket if your vehicle is damaged in an accident. Your insurance company covers the remaining amount.</p>
                        </ReactTooltip>
                    </div>

                        <select
                            defaultValue={localState.collision.deductible}
                            name='deductible'
                            className='formSelect'
                            onChange={(event)=>{onSelectChange(event,'collision')}}
                        >
                            <option value="" disabled={true}>Select from the list</option>
                            <option value="0">No Coverage</option>
                            <option value="500">$500 Deductible</option>
                            <option value="1000">$1,000 Deductible</option>
                            <option value="1500">$1,500 Deductible</option>
                        </select>

                    <div className="formLabelAndTooltipWrapper">
                        <h4>Third Party Liability</h4>
                        <div className="tooltipIcon" data-tip={true} data-for='thirdPartyLiability'/>
                        <ReactTooltip id='thirdPartyLiability' className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">This protects you from lawsuits resulting from accidents causing bodily injury or death to others or property damage.</p>
                        </ReactTooltip>
                    </div>

                    <select
                        defaultValue={localState.liability.limit}
                        name='limit'
                        className='formSelect'
                        onChange={(event)=>{onSelectChange(event,'liability')}}
                    >
                        <option value="" disabled={true}>Select from the list</option>
                        <option value="1000000">$1,000,000</option>
                        <option value="2000000">$2,000,000</option>
                    </select>


                    {
                        quoteErrorMessage &&
                        <p>{quoteErrorMessage}</p>
                    }

                    <button className="addAnotherField"
                            onClick={() => {
                                if (quoteRequestState !== requestStatusTypes.Loading) {
                                    postRequestQuote(false, true, true, false);
                                }
                            }}>
                        Recalculate Quote
                    </button>

                    <EndorsementsRecalc />
                </>
            }
        </div>
    )

}
export default VehicleRecalc;
