import React, {useContext, useEffect, useReducer, useState} from "react"
import ReactTooltip from "react-tooltip";
import moment from "moment";

// contexts
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import ViewContext from "../../../contexts/ViewContext";
// actions
import {viewLoaderActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
// managers
import {CarDataManager} from "../../../managers/DataManagers/CarDataManager";

// status types
import {requestStatusTypes} from "../../../config/constants";

// helpers
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {arrayReducer, arrayReducerActionTypes} from '../../../helpers/arrayReducer';
import {activeItemReducerActionTypes, activeItemStateReducer} from "../../../helpers/activeItemState";
import {formStateActionTypes, formStateReducer} from "../../../helpers/formState";

// local constants
import {
    yearMessage,
    eachDayKilometresRange,
    eachDayKilometresRangeForBusiness,
    eachYearKilometresRange
} from './constants';

import {vehicleYearRange, returnPurchaseMinDate, checkRecommendedRange} from './helper';

import VehicleDateInput from "../../ui/vehicleFormInputs/VehicleDateInput";
import FormBottomNavigationButtons from "../../ui/buttons/FormBottomNavigationButtons";
import {currentDate} from "../../../config/dateConfig";


const FormOneWrapper = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 3});

    const {carRequestState, getCarModel, getCarManufacture, carModelResponse, carManufactureResponse} = CarDataManager();

    const [localState, updateLocalState] = useReducer(formStateReducer, {});


    const [recommendedRange, updateRecommendedRange] = useState(0);


    const [manufactureList, updateManufactureList] = useReducer(arrayReducer, []);
    const [modelList, updateModelList] = useReducer(arrayReducer, []);

    const {vehicleData, vehicleTab, updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);
    const {viewLoader, updateViewLoader} = useContext(ViewContext);

    const [localActiveItem, updateLocalActiveItem] = useReducer(activeItemStateReducer, {activeItem: -1});

    // handle select
    function handleSelect(event) {
        updateLocalState({type:formStateActionTypes.Update,payload:{
                ...localState,
                [event.target.name]: event.target.value
            }
        });
    }

    function handleSwitchButton(name, value) {
        updateLocalState({type:formStateActionTypes.Update,payload:{
                ...localState,
                [name]: value
            }
        });
    }

    function handleSwitchButtonNestedObject(rootObject, nestedObject, value) {
        updateLocalState({type:formStateActionTypes.Update,payload:{
                ...localState,
                [rootObject]: {
                    ...localState[rootObject],
                    [nestedObject]: value
                }
            }
        });
    }

    function handleDate(event) {
        updateLocalState({
            type: formStateActionTypes.UpdateVariable,
            name: event.target.name,
            payload: event.target.value
        });

    }

    // update store from local state
    useEffect(() => {

        if (renderState.isRender && !renderState.isFirst && localActiveItem.activeItem===vehicleTab.activeItem) {
            if (localState.year !== vehicleData.items[localActiveItem.activeItem].year) {
                updateLocalState({type:formStateActionTypes.Update,payload:{
                        ...localState,
                        make: '', model: ''
                    }
                });
                updateManufactureList({type: arrayReducerActionTypes.CleanArray});
                updateModelList({type: arrayReducerActionTypes.CleanArray});
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateYear,
                    payload: localState.year,
                    index: localActiveItem.activeItem
                });
                getCarManufacture(localState.year, true);
            }

            if (localState.make !== vehicleData.items[localActiveItem.activeItem].make) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateMake,
                    payload: localState.make,
                    index: localActiveItem.activeItem
                });
                if (localState.make) {
                    updateModelList({type: arrayReducerActionTypes.CleanArray});
                    updateLocalState({type:formStateActionTypes.Update,payload:{
                            ...localState,
                            model: ''
                        }
                    });
                    getCarModel(localState.year, localState.make, true);
                }
                updateVehicleTab({
                    type: vehicleDataActionTypes.UpdateTabName,
                    index: localActiveItem.activeItem,
                    payload: localState.make
                });
            }

            if (localState.model !== vehicleData.items[localActiveItem.activeItem].model) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateModel,
                    payload: localState.model,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.condition !== vehicleData.items[localActiveItem.activeItem].condition) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateCondition,
                    payload: localState.condition,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.leased !== vehicleData.items[localActiveItem.activeItem].leased) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateLeased,
                    payload: localState.leased,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.purchase_date !== vehicleData.items[localActiveItem.activeItem].purchase_date) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdatePurchaseDate,
                    payload: localState.purchase_date,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.winter_tires !== vehicleData.items[localActiveItem.activeItem].winter_tires) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateWinterTires,
                    payload: localState.winter_tires,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.parking_location !== vehicleData.items[localActiveItem.activeItem].parking_location) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateParkingLocation,
                    payload: localState.parking_location,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.primary_use !== vehicleData.items[localActiveItem.activeItem].primary_use) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdatePrimaryUse,
                    payload: localState.primary_use,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.distance_daily !== vehicleData.items[localActiveItem.activeItem].distance_daily) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateDistanceDaily,
                    payload: localState.distance_daily,
                    index: localActiveItem.activeItem
                });
                updateRecommendedRange(checkRecommendedRange(localState.distance_daily, localState.distance_business));
            }

            if (localState.distance_business !== vehicleData.items[localActiveItem.activeItem].distance_business) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateDistanceBusiness,
                    payload: localState.distance_business,
                    index: localActiveItem.activeItem
                });
                updateRecommendedRange(checkRecommendedRange(localState.distance_daily, localState.distance_business));
            }

            if (localState.distance_yearly !== vehicleData.items[localActiveItem.activeItem].distance_yearly) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateDistanceYearly,
                    payload: localState.distance_yearly,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.comprehensive.coverage !== vehicleData.items[localActiveItem.activeItem].comprehensive.coverage) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateComprehensive,
                    payload: localState.comprehensive.coverage,
                    index: localActiveItem.activeItem
                });
            }

            if (localState.collision.coverage !== vehicleData.items[localActiveItem.activeItem].collision.coverage) {
                updateVehicleData({
                    type: vehicleDataActionTypes.UpdateCollision,
                    payload: localState.collision.coverage,
                    index: localActiveItem.activeItem
                });
            }


        }
    }, [localState]);


    // response use effect
    useEffect(() => {
        updateModelList({type: arrayReducerActionTypes.UpdateArray, payload: carModelResponse});
    }, [carModelResponse]);

    useEffect(() => {
        updateManufactureList({type: arrayReducerActionTypes.UpdateArray, payload: carManufactureResponse});

    }, [carManufactureResponse]);


    // preload useeffects
    useEffect(() => {
        if (!renderState.isFirst && !renderState.isRender) {
            if (localState.liability && localActiveItem.activeItem===vehicleTab.activeItem) {
                if (localState.year) {

                    if (!renderState.isLoading) {

                        if (renderState.requestCount === 1) {
                            updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 0});
                        }

                        if (renderState.requestCount === 2) {
                            if (localState.make) {
                                getCarModel(localState.year, localState.make, false);
                                updateRenderState({type: renderStateActionTypes.UpdateLoading, payload: true});
                                updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                            } else {
                                updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 0});
                            }
                        }

                        if (renderState.requestCount === 3) {
                            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: true});
                            getCarManufacture(localState.year, false);
                            updateRenderState({type: renderStateActionTypes.UpdateLoading, payload: true});
                            updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 2});
                        }
                    } else {
                        if (carRequestState === requestStatusTypes.Success || carRequestState === requestStatusTypes.Error) {
                            updateRenderState({type: renderStateActionTypes.UpdateLoading, payload: false});
                        }
                    }
                } else {
                    updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 0});
                }


            }
        }
    }, [renderState, localState, carRequestState]);


    useEffect(() => {
        let intervalLoader = null;
        if (renderState.requestCount === 0 && !renderState.isRender && !renderState.isFirst && !renderState.isLoading && localActiveItem.activeItem===vehicleTab.activeItem) {
            updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
        }
        if (renderState.isRender && localState.liability) {
            updateRecommendedRange(checkRecommendedRange(localState.distance_daily, localState.distance_business));
            if (viewLoader.isOpen) {
                updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});

            }
        }
        return () => {
            clearTimeout(intervalLoader)
        };
    }, [renderState, localState.liability]);

    useEffect(() => {
        if (!renderState.isFirst) {
            //updateLocalState({});
            updateLocalState({type:formStateActionTypes.Clear});
            updateRenderState({type: renderStateActionTypes.Clear, payload: {requestCount: 3}});
        }
    }, [vehicleTab, vehicleTab.activeItem]);

    useEffect(() => {
        if (renderState.isFirst) {
            if (Object.keys(localState).length === 0) {

                updateLocalState({type:formStateActionTypes.Update,payload:{...vehicleData.items[vehicleTab.activeItem]}});
                updateLocalActiveItem({type:activeItemReducerActionTypes.UpdateActive, payload: vehicleTab.activeItem});
                updateManufactureList({type: arrayReducerActionTypes.CleanArray});
                updateModelList({type: arrayReducerActionTypes.CleanArray});

                updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            }
        }
    });

    return (
        <>
            {
                renderState.isRender && localActiveItem.activeItem === vehicleTab.activeItem &&
                <>
                    <div className="formWrapper">

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Car Year</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='vehicleYear'/>
                            <ReactTooltip id='vehicleYear' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Every car is different, so for insurers to accurately cover,
                                    repair, or replace your vehicle, they need to know the make, model, age, body type,
                                    and vehicle history. Each factor will raise or lower your premium—an older vehicle
                                    is typically more affordable to repair, for example, since cars lose value each
                                    year.</p>
                            </ReactTooltip>
                        </div>
                        <select
                            className='formSelect'
                            name='year'
                            onChange={handleSelect}
                            defaultValue={localState.year}
                        >
                            <option value='' disabled={true}>Select year</option>
                            {
                                vehicleYearRange(localState.year)
                            }
                            <option value="2000">2000 or older</option>
                        </select>

                        {
                            localState.year === '2000' &&
                            <p className="warningMessage">
                                {yearMessage}
                            </p>

                        }

                        {localState.year && manufactureList.length > 0 &&
                        <>
                            <div className="formLabelAndTooltipWrapper">
                                <h4>Car Manufacturer</h4>
                                <div className="tooltipIcon" data-tip={true} data-for='vehicleMake'/>
                                <ReactTooltip id='vehicleMake' className="tooltipWrapper" arrowColor="transparent">
                                    <p className="tooltipText">The manufacturer of your vehicle is a huge influence on
                                        your insurance premium, but it isn’t the only consideration. Some car
                                        manufacturers include security features that may qualify for discounts, like
                                        anti-theft protection or anti-lock brakes. Insurers also consider repair, risk,
                                        injury, and retail prices. If you do not see your vehicle on our list, call us
                                        for a telephonic quote.</p>
                                </ReactTooltip>
                            </div>
                            <select name="make"
                                    className="formSelect"
                                    defaultValue={localState.make}
                                    onChange={handleSelect}
                            >
                                <option value="" disabled={true}>Select a Make</option>
                                {manufactureList.map(vehicle => (
                                    <option key={vehicle.make} value={vehicle.make}>{vehicle.make}</option>))}
                            </select>
                        </>
                        }


                        {localState.make && modelList.length > 0 && <>
                            <div className="formLabelAndTooltipWrapper">
                                <h4>Car Model</h4>
                                <div className="tooltipIcon" data-tip={true} data-for='vehicleModel'/>
                                <ReactTooltip id='vehicleModel' className="tooltipWrapper" arrowColor="transparent">
                                    <p className="tooltipText">Insurers use the model to calculate insurance premiums.
                                        It may not significantly impact basic liability, but it does influence collision
                                        and comprehensive car insurance premiums. Safety features are also pivotal.
                                        Rear-view cameras and anti-theft devices decrease the likelihood of a claim, and
                                        larger cars risk more damage if you do have an accident. If you do not see your
                                        vehicle on our list, call us for a telephonic quote.</p>
                                </ReactTooltip>
                            </div>
                            <select name="model" className="formSelect"
                                    defaultValue={localState.model}
                                    onChange={handleSelect}
                            >
                                <option value="" disabled={true}>Select a Model</option>
                                {modelList.map(vehicle => (
                                    <option key={vehicle.model} value={vehicle.model}>{vehicle.model}</option>))}
                            </select>
                        </>
                        }


                        <div className="formLabelAndTooltipWrapper">
                            <h4>Was this car new or used when you bought it?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='newCar'/>
                            <ReactTooltip id='newCar' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Used cars are usually cheaper to insure than new ones because
                                    of the depreciation. The cost to replace this vehicle is typically less expensive
                                    and insurers will account for the condition of the car when giving you a rate.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.condition === "N" ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButton('condition', 'N')
                                    }}
                            >
                                New
                            </button>
                            <button className={localState.condition === "U" ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButton('condition', 'U')
                                    }}
                            >
                                Used
                            </button>
                        </div>


                        <div className="formLabelAndTooltipWrapper">
                            <h4>Is this car owned/financed or leased?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='ownCar'/>
                            <ReactTooltip id='ownCar' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">You can own or lease a car. Ownership means that you bought
                                    the vehicle or are currently financing it. Leasing is renting it under a long-term
                                    contract. In this case, the leasing company maintains ownership of the vehicle,
                                    which you will see clearly on your insurance policy.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.leased ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButton('leased', true)
                                    }}
                            >
                                Leased
                            </button>
                            <button className={!localState.leased ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButton('leased', false)
                                    }}
                            >
                                Owned
                            </button>
                        </div>


                        <div className="formLabelAndTooltipWrapper">
                            <h4>When did you buy this car?</h4>
                        </div>

                        <VehicleDateInput
                            type='month'
                            name='purchase_date'
                            defaultValue={localState.purchase_date ? localState.purchase_date : ''}
                            min={returnPurchaseMinDate(localState.year)}
                            max={moment(currentDate).local().format("YYYY-MM-DD")}
                            parentName={false}
                            handleDate={handleDate}
                            placeholder='Select a date'
                            disabled={false}
                        />



                        <div className="formLabelAndTooltipWrapper nextToCalendarInput">
                            <h4>Does your car have winter tires?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='winterTires'/>
                            <ReactTooltip id='winterTires' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Snow. Ice. Freezing temperatures. Winter tires protect you
                                    from all these winter weather conditions, and more. It also protects your wallet.
                                    Investing in winter tires can save you $50 to $100 on your insurance.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.winter_tires ? 'selectedBtn' : 'deSelectedBtn'}
                                    value={localState.winter_tires}
                                    onClick={() => {
                                        handleSwitchButton('winter_tires', true)
                                    }}>Yes
                            </button>
                            <button className={!localState.winter_tires ? 'selectedBtn' : 'deSelectedBtn'}
                                    value={!localState.winter_tires}
                                    onClick={() => {
                                        handleSwitchButton('winter_tires', false)
                                    }}>No
                            </button>
                        </div>


                        <div className="formLabelAndTooltipWrapper">
                            <h4>Where do you keep your car overnight?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='parkingPlace'/>
                            <ReactTooltip id='parkingPlace' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText"> For many of us, the answer is “at home.” If you park your
                                    car in various locations throughout the year, then select the most frequently
                                    parking spot. Be honest—some insurers will use your overnight parking location to
                                    calculate a quote, which could affect your claims in the future.</p>
                            </ReactTooltip>
                        </div>
                        <select
                            defaultValue={localState.parking_location}
                            onChange={handleSelect}
                            name='parking_location' className="formSelect">
                            <option value="" disabled={true}>Select from the list</option>
                            <option value="garage">Private Garage</option>
                            <option value="driveway">Private Driveway</option>
                            <option value="underground">Underground Parking</option>
                            <option value="lot">Parking Lot</option>
                            <option value="carport">Carport</option>
                            <option value="storage">Indoor Storage</option>
                            <option value="street">Street</option>
                            <option value="other">Other</option>
                        </select>


                        <div className="formLabelAndTooltipWrapper">
                            <h4>What do you mainly use your car for?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='primaryUse'/>
                            <ReactTooltip id='primaryUse' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">The way you use your vehicle is a primary factor that
                                    insurers use in your policy terms and fees, whether it be personal or
                                    business. <br/>
                                    • Personal is driving from work or school to home.<br/>
                                    • Business considers other uses like sales calls, pick-ups or deliveries, or other
                                    business errands.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.primary_use === "personal" ? "selectedBtn" : "deSelectedBtn"}
                                    onClick={() => {
                                        handleSwitchButton('primary_use', 'personal')
                                    }}>Personal
                            </button>
                            <button className={localState.primary_use === "business" ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButton('primary_use', 'business')
                                    }}>Business
                            </button>
                        </div>
                        {
                            localState.primary_use === "business" &&
                            <p className="recommendedRangeInfo">Please choose "Business" only if your vehicle is used
                                primarily
                                for work
                                (sales representatives, realtors, consultants, etc.). If you drive back and forth to
                                work or use
                                your
                                vehicle
                                occasionally for work, please select "Personal".</p>
                        }

                        <div className="formLabelAndTooltipWrapper">
                            <h4>How far is your 1-way commute to <br /> work or school</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='dailyKilometer'/>
                            <ReactTooltip id='dailyKilometer' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">The distance you drive to work or school would be an example
                                    of your daily commute. It is one of the most important factors when determining
                                    coverage options since it exposes you to traffic each day. The shorter your commute,
                                    the less risk you carry of an accident.</p>
                            </ReactTooltip>
                        </div>
                        <select
                            name="distance_daily" className="formSelect"
                            onChange={handleSelect}
                            defaultValue={localState.distance_daily}
                        >
                            <option value="" disabled={true}>Select</option>
                            {
                                eachDayKilometresRange.map((value, index) =>{
                                    if (value === 1) {
                                        return <option key={index} value={value}>1KM or Less</option>;
                                    }
                                    return<option key={index} value={value}>{value}KM</option>
                                })
                            }
                        </select>

                        {
                            localState.primary_use === 'business' &&
                            <>
                                <div className="formLabelAndTooltipWrapper">
                                    <h4>How many kilometers are driven for business <br/> use each day?</h4>
                                </div>
                                <select
                                    name="distance_business" className="formSelect"
                                    defaultValue={localState.distance_business}
                                    onChange={handleSelect}
                                >
                                    <option value="" disabled={true}>Select</option>
                                    {
                                        eachDayKilometresRangeForBusiness.map((value, index) =>
                                            <option key={index} value={value}>{value} KM</option>)
                                    }
                                </select>
                            </>
                        }

                        <div className="formLabelAndTooltipWrapper">
                            <h4>How many total kilometers are driven each year?</h4>
                        </div>
                        <select
                            name="distance_yearly" className="formSelect"
                            defaultValue={localState.distance_yearly}
                            onChange={handleSelect}
                        >
                            <option disabled hidden value="">Select</option>
                            {
                                eachYearKilometresRange.map((value, index) =>
                                    <option key={index} value={value}>{value} KM</option>)
                            }
                        </select>

                        {localState.distance_business || localState.distance_daily ?
                            <p className="recommendedRangeInfo">Based on your daily driven kilometers, we
                                recommend {recommendedRange} for
                                your yearly driven kilometers</p> : ''}

                    </div>
                    <div className="bottomFormWrapper">

                        <h2>Do you want your insurance to have either comprehensive, collision coverage or
                            both?
                        </h2>

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Comprehensive Coverage</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='comprehensiveCoverage'/>
                            <ReactTooltip id='comprehensiveCoverage' className="tooltipWrapper"
                                          arrowColor="transparent">
                                <p className="tooltipText">Comprehensive insurance covers factors outside of traffic,
                                    like fire, flooding, and theft. It also includes terms, limits, and conditions,
                                    which become more favourable as the car ages and devalues. Comprehensive coverage is
                                    ideal for newer or lightly used vehicles, but for older vehicles, it tends to be
                                    poor value for money.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.comprehensive.coverage ? "selectedBtn" : "deSelectedBtn"}
                                    onClick={() => {
                                        handleSwitchButtonNestedObject('comprehensive', 'coverage', true)
                                    }}>Yes
                            </button>
                            <button className={!localState.comprehensive.coverage ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButtonNestedObject('comprehensive', 'coverage', false)
                                    }}>No
                            </button>
                        </div>

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Collision Coverage</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='collisionCoverage'/>
                            <ReactTooltip id='collisionCoverage' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Sometimes, you are at-fault in a collision. Collision
                                    coverage pays for repairs when you hit another car or object. It may also cover
                                    damage if another driver hits your vehicle, and their insurance doesn't cover the
                                    repairs. You will have to pay a deductible before the insurer begins to pay for
                                    damages. If another driver hits you without collision coverage, the liability
                                    portion of your insurance kicks in, and liability insurance will cover the other
                                    driver if you are at fault. The definition of at-fault varies based on location.
                                    When the cost of repairs exceeds the car's value, it is time to forgo collision
                                    coverage as unnecessary.</p>
                            </ReactTooltip>
                        </div>
                        <div className="formButtonsWrapper">
                            <button className={localState.collision.coverage ? "selectedBtn" : "deSelectedBtn"}
                                    onClick={() => {
                                        handleSwitchButtonNestedObject('collision', 'coverage', true)
                                    }}>Yes
                            </button>
                            <button className={!localState.collision.coverage ? 'selectedBtn' : 'deSelectedBtn'}
                                    onClick={() => {
                                        handleSwitchButtonNestedObject('collision', 'coverage', false)
                                    }}>No
                            </button>
                        </div>

                    </div>
                    <FormBottomNavigationButtons next='/driver' isAddVehicle={true}/>
                </>
            }
        </>
    );
}
export default FormOneWrapper;
