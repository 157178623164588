import {useContext, useReducer} from "react";
import {validationManagerReducer} from "./validationManagerReducer";
import {NO_ERRORS, validationManagerActionTypes, validationManagerState, validationStatusTypes} from "./constants";
import ViewContext from "../../contexts/ViewContext";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";
import DriverDataContext, {LicenseTypes} from "../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";


const StepTwoValidationManager = () => {


    const [localState, updateLocalState] = useReducer(validationManagerReducer, validationManagerState);

    const {driverData, updateDriverTab} = useContext(DriverDataContext);
    const {discountData} = useContext(DiscountDataContext);

    const {updateViewRedirect, updateViewModal} = useContext(ViewContext);

    function returnObjectValid(obj, id) {
        let errorMessage = NO_ERRORS;

        if (!obj.first_name) {
            return `Please enter Driver ${id}'s first name`;
        }

        if (!obj.marital_status) {
            return `Please enter ${obj.first_name}'s marital status`;
        }

        if (!obj.date_of_birth) {
            return `Please enter ${obj.first_name}'s date of birth`;
        }

        if(obj.applicant_relationship==='' && id>1){
            return `Please choose ${obj.first_name}'s relationship to applicant`;
        }

        if (!obj.licence_info.licence_type) {
            return `Please choose ${obj.first_name}'s licence type`;
        }


        if (!obj.licence_info.first_licence_age) {
            return `Please choose ${obj.first_name}'s first licence age`;
        }

        if (obj.licence_info.licence_type === LicenseTypes.g) {
            if (!obj.licence_info.g_licence_date) {
                return `Please choose ${obj.first_name}'s g licence date`;
            }
            if (!obj.licence_info.g2_licence_date) {
                return `Please choose ${obj.first_name}'s g2 licence date`;
            }
        }

        if (obj.licence_info.licence_type === LicenseTypes.gOne) {
            if (!obj.licence_info.g1_licence_date) {
                return `Please choose ${obj.first_name}'s g1 licence date`;
            }
        }

        if (obj.licence_info.licence_type === LicenseTypes.gTwo) {
            if (!obj.licence_info.g2_licence_date) {
                return `Please choose ${obj.first_name}'s g2 licence date`;
            }
        }

        if (obj.listed) {
            if (!obj.listed_year) {
                return `Please choose ${obj.first_name}'s listed year`;
            }

            if (obj.insured) {
                if (!obj.insured_year) {
                    return `Please choose ${obj.first_name}'s insured year`;
                }
            }
        }

        if(id===1){
            if(!discountData.policy_start){
                return `Please choose ${obj.first_name}'s new insurance start date`;
            }
        }

        if (obj.insurance_cancellation) {
            for (let z = 0; z < obj.insurance_cancellation_list.length; z++) {
                //if(obj.insurance_cancellation_list[z].)
                if (!obj.insurance_cancellation_list[z].reason) {
                    return `Please choose ${obj.first_name}'s insurance cancellation reason`;
                }
                if (!obj.insurance_cancellation_list[z].start_date) {
                    return `Please choose ${obj.first_name}'s insurance cancellation start date`;
                }
                if (!obj.insurance_cancellation_list[z].end_date) {
                    return `Please choose ${obj.first_name}'s insurance cancellation end date`;
                }
            }
        }

        if (obj.licence_suspension) {
            for (let z = 0; z < obj.licence_suspension_list.length; z++) {
                //if(obj.insurance_cancellation_list[z].)
                if (!obj.licence_suspension_list[z].reason) {
                    return `Please choose ${obj.first_name}'s licence suspension reason`;
                }
                if (!obj.licence_suspension_list[z].suspension_date) {
                    return `Please choose ${obj.first_name}'s licence suspension date`;
                }
                if (!obj.licence_suspension_list[z].reinstatement_date) {
                    return `Please choose ${obj.first_name}'s licence reinstatement date`;
                }
            }
        }

        if (obj.accident) {
            for (let z = 0; z < obj.accident_list.length; z++) {
                if (!obj.accident_list[z].accident_date) {
                    return `Please choose ${obj.first_name}'s accident date`;
                }
            }
        }

        if (obj.ticket) {
            for (let z = 0; z < obj.ticket_list.length; z++) {
                if (!obj.ticket_list[z].reason) {
                    return `Please choose ${obj.first_name}'s ticket reason`;
                }
                if (!obj.ticket_list[z].ticket_date) {
                    return `Please choose ${obj.first_name}'s ticket date`;
                }
            }
        }

        return errorMessage;
    }

    const localValidation = () => {

        updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.InProcess});

        let allErrors = [];

        if (driverData.items) {
            if (driverData.items.length > 0) {
                for (let k in driverData.items) {
                    let obj = returnObjectValid(driverData.items[k], (Number(k) + 1));

                    if (obj !== NO_ERRORS) {
                        if (allErrors.length < 1) {
                            updateDriverTab({type:driverDataActionTypes.SelectItem,payload: Number(k)});
                        }
                        allErrors.push(obj);
                    }
                }
            } else {
                allErrors.push('You have no Drivers, please enter your Driver');
            }
        } else {
            allErrors.push('You have no Drivers, please enter your Driver');
        }
        if (allErrors.length > 0) {
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: allErrors[0],
                description: '',
                submitPath: ''
            });
            updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/driver'});
            updateLocalState({
                type: validationManagerActionTypes.UpdateProcess,
                payload: validationStatusTypes.Rejected
            });
        } else {
            updateLocalState({type: validationManagerActionTypes.UpdateValid, payload: true});
            updateLocalState({
                type: validationManagerActionTypes.UpdateProcess,
                payload: validationStatusTypes.Validated
            });
        }
    }

    return {
        stepTwoIsValid: localState.isValid,
        stepTwoStatus: localState.status,
        stepTwoValidation: localValidation
    }
};

export default StepTwoValidationManager;
