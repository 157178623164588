import React, {useContext, useEffect, useState} from "react";

import ViewContext from "../../../../contexts/ViewContext";
import {AuthDataManager} from "../../../../managers/DataManagers";

import AppHeaderMobile from "./mobile/AppHeaderMobile";
import AppHeaderDesktop from "./desktop/AppHeaderDesktop";

const AppHeaderWrapper = () => {

    const [wrapperStyle, setWrapperStyle] = useState({});
    const {viewSize, viewHeader} = useContext(ViewContext);

    const {signOut} = AuthDataManager();

    useEffect(()=>{

        let mobileHeaderTimeOut;

        if(viewSize.isMobile){
            if(viewHeader.isMobileOpen){
                mobileHeaderTimeOut = setTimeout(()=>{setWrapperStyle({position:'fixed'});},490);

            }
            else{
                setWrapperStyle({position:'relative'});
            }
        }
        else{
            setWrapperStyle({});
        }

        return ()=>{
            clearTimeout(mobileHeaderTimeOut);
        }
    },[viewSize.isMobile, viewHeader]);

    return(
        <div className="appNavbarWrapper" style={wrapperStyle}>
            {
                viewSize.isMobile &&
                <AppHeaderMobile signOut={signOut}/>
            }
            {
                !viewSize.isMobile &&
                <AppHeaderDesktop signOut={signOut}/>
            }
        </div>
    );
};

export default AppHeaderWrapper;
