import React, {useContext, useEffect, useReducer} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import PostalDataManager from "../managers/DataManagers/PostalDataManager";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";
import {requestStatusTypes} from "../config/constants";
import queryJson from "../helpers/queryJson";
import DiscountDataContext, {discountDataActionTypes} from "../contexts/formContexts/DiscountDataContext";

const PostalCode = () => {

    const {getPostal, postalRequestState} = PostalDataManager();
    const { updateDiscountData } = useContext(DiscountDataContext);

    let history = useHistory();



    const queryRoot = useLocation().search.split('?');

    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);



    useEffect(()=>{
        if(!renderState.isFirst){
            if(postalRequestState!==requestStatusTypes.Loading && postalRequestState!==requestStatusTypes.Waiting){
                history.push('/');
            }
        }

    },[renderState, postalRequestState])

    useEffect(()=>{
        if(renderState.isFirst){
            if(queryRoot.length>1){
                const query = queryJson(queryRoot);

                if (query.province_name) {
                    updateDiscountData({type: discountDataActionTypes.UpdateProvinceName, payload: query.province_name});
                }

                if (query.province_code) {
                    updateDiscountData({type: discountDataActionTypes.UpdateProvinceCode, payload: query.province_code});
                }

                if(query.postal_code){
                    getPostal(query.postal_code, false);
                }
                else{
                    history.push('/');
                }
            }
            else{
                history.push('/');
            }
            updateRenderState({type:renderStateActionTypes.UpdateFirst,payload:false})
        }
    })

    return (
        <>
        </>
    );
};

export default PostalCode;
