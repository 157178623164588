import {quoteDataActionTypes} from "./quoteDataActions";

function updateQuotes(quotes, state, isRated){

    let quoteViewTab = [];

    for(let z=0 ; z<quotes.length; z++){
        quoteViewTab.push({
            index: z,
            isVisible: z === 0
        })
    }

    const newQuotes = {
        ...state,
        quotes:[...quotes],
        quoteTab:[...quoteViewTab],
        isRated: isRated ? isRated : false,
    };

    localStorage.setItem('quoteData',JSON.stringify(newQuotes));

    return newQuotes;
}

function updateQuoteTab(state, id, value) {

    state.quoteTab[id].isVisible = value;

    localStorage.setItem('quoteData',JSON.stringify(state));

    return state;
}

export const quoteDataReducer = (state, action) => {

    switch (action.type) {

        case quoteDataActionTypes.UpdateQuote:
            return updateQuotes(action.payload, state, action.isRated ? action.isRated : false);

        case quoteDataActionTypes.UpdateTab:
            return {...updateQuoteTab(state, action.index, action.payload)};

        case quoteDataActionTypes.ClearQuote:
            return updateQuotes([]);

        case quoteDataActionTypes.UpdateRate:
            return {...state, isRated: action.payload};

        case quoteDataActionTypes.UpdateCallMessage:
            return {...state, showCallMessage: action.payload};

        default:
            return state;

    }
}
