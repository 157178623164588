import {driverDataActionTypes} from "./driverDataActionTypes";
import cloneDeep from 'lodash/cloneDeep';
import {calculateMinDates, clearGLicence, resetGDefaultValues} from '../../../helpers/calculateGLicence';
import {driverDataItemInitialState} from "./initialValues";
import { getDriverDataInitialState } from './helper';

function updateLocalData(state) {
    localStorage.setItem('driverData', JSON.stringify(state));
}

function addNewDriver(state, licenceAge) {
    let newDriver = cloneDeep(getDriverDataInitialState(licenceAge));
    state.items.push(newDriver);
    updateLocalData(state);
    return state;
}

function removeDriver(state, removeIndex) {
    const newItems = state.items.filter((item, index) => index !== removeIndex);
    updateLocalData({items: [...newItems]});
    return {items: [...newItems]};
}

function clearDriverData() {
    const newDrivers = {items: [cloneDeep(driverDataItemInitialState)]};
    updateLocalData(newDrivers);
    return newDrivers;
}

export const driverDataReducer = (state, action) => {

    let newState = {...state};

    switch (action.type) {

        case driverDataActionTypes.Clear:
            return clearDriverData();

        case driverDataActionTypes.AddItem:
            return addNewDriver(state);

        case driverDataActionTypes.RemoveItem:
            return removeDriver(state, action.payload);

        case driverDataActionTypes.UpdateFirstName:
            state.items[action.index].first_name = action.payload;
            updateLocalData(state);
            return state;

        case driverDataActionTypes.UpdateMarital:
            newState.items[action.index].marital_status = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateDateOfBirth:
            newState.items[action.index].date_of_birth = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config, true)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index], true)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateOccupation:
            newState.items[action.index].occupation = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateGender:
            newState.items[action.index].gender = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateRelation:
            newState.items[action.index].applicant_relationship = action.payload;
            updateLocalData({...newState});
            return {...newState};

        // license info
        case driverDataActionTypes.UpdateLicenceAge:
            newState.items[action.index].licence_info.first_licence_age = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config, true)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index], true)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceType:
            newState.items[action.index].licence_info.licence_type = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index])};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGDate:
            newState.items[action.index].licence_info.g_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGTwoDate:
            newState.items[action.index].licence_info.g2_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGOneDate:
            newState.items[action.index].licence_info.g1_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdatePassedDriverTraining:
            newState.items[action.index].licence_info.passed_driver_training = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceAllInfo:
            newState.items[action.index].licence_info = {...newState.items[action.index].licence_info, ...action.payload};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicencePrevious:
            newState.items[action.index].licence_info.previous_licence = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListed:
            newState.items[action.index].listed = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListedYear:
            newState.items[action.index].listed_year = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateInsured:
            newState.items[action.index].insured = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateInsuredYear:
            newState.items[action.index].insured_year = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListNavigation:
            newState.items[action.index][action.name] = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListContent:
            newState.items[action.index][action.name] = action.payload;
            updateLocalData({...newState});
            return {...newState};

        default:
            return state;
    }
}
