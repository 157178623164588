
/* Global Data Context Actions*/

export const globalDataActionTypes = {
    UpdateUser: 'UpdateUser',
    UPDATE_UID: 'UPDATE_UID',
    UpdateAuth: 'UpdateAuth',
    ClearUser: 'ClearUser',
    UpdatePostal: 'UpdatePostal',
    UpdatePostalErrors: "UpdatePostalErrors",
    GetLocalPostal: "GetLocalPostal",
    ClearPostal: 'ClearPostal',
    UpdateSignedInRedirect: 'UpdateSignedInRedirect',
};
