import React, {useState, useContext, useMemo} from 'react';
import {Link} from 'react-router-dom';
import ViewContext from "../../contexts/ViewContext";

//Assets
import RightArrowIcon from '../../resources/images/right-arrow-quotes.svg';
import {viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";


const QuoteList = React.memo((props) => {

    const {viewSize, updateViewRedirect} = useContext(ViewContext);
    const [activeItems, setActiveItems] = useState([]);

    const localIsMobile = useMemo(() => {
        return viewSize.isMobile;
    }, [viewSize.isMobile]);

    const localQuotes = useMemo(()=>{
        let newActiveItems = [];
        for(let k in props.quoteItems){
            newActiveItems.push({isActive:false});
        }
        setActiveItems([...newActiveItems]);
        return [...props.quoteItems];
    },[props.quoteItems]);

    const toggleAccordion = (index) => {
        const newActiveItems = activeItems;
        newActiveItems[index].isActive = !newActiveItems[index].isActive;
        setActiveItems([...newActiveItems]);
    }


    return (
        <div className="addVehiclesFormContentWrapper">
            <div className="quotesWrapper">
                <div className="quotesTitle">
                    <h3>Quotes History</h3>
                </div>
                {!localQuotes.length  &&
                <h4 className="noQuotesHistoryInfo">You have no saved quotes yet</h4>
                }
                {
                    !localIsMobile && activeItems.length === localQuotes.length &&
                    <>
                        {
                            localQuotes.map((item,index)=>{return(
                                <div className="quotesContainer" key={`quote${index}`}>
                                    <div className={`wrap ${activeItems[index].isActive && "quoteDetailOpened"}`}>
                                        <div className="accordionWrapper">
                                            <div className={`quote`}>
                                                <div className="newBestOfferWrapper">
                                                    <h4>Quote {localQuotes.length - index}</h4>
                                                    <p className="newBestOffer">New Best Offer</p>
                                                </div>
                                                <p>{item.date}</p>
                                                <p>${item.price_yearly} yearly</p>
                                                <p>${item.price_monthly} monthly</p>
                                                <div className="viewDetails">
                                                    <Link to={`/profile/quotes-history/${item.id}`}>View Detail</Link>
                                                    <div className={`rightArrowQuotes ${activeItems[index].isActive && "upArrowQuotes"}`}
                                                         onClick={() => {toggleAccordion(index)}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordionWrapper">
                                        <div className={`accordionContent ${activeItems[index].isActive && 'accordionContentOpened'}`}>
                                            <div className="quote firstQuote  hiddenBorder accordionWrapper">
                                                <p>Vehicle</p>
                                                <p>Insurance Company</p>
                                                <p>Yearly$</p>
                                                <p>Monthly $</p>
                                            </div>

                                              {item.vehicles.map((vehicleItem, vehicleIndex) => {
                                                return (
                                                  <div className="quote hiddenBorder accordionWrapper" key={`vehicle${vehicleIndex}`}>
                                                    <div>
                                                      <p className="careMake">{vehicleItem.make}</p>
                                                      <p className="paragraph">{vehicleItem.model} {vehicleItem.year}</p>
                                                    </div>
                                                    <p>{item.company.name}</p>
                                                    <p>${vehicleItem.price_yearly}</p>
                                                    <p>${vehicleItem.price_monthly}</p>
                                                  </div>
                                                )
                                              })
                                              }

                                        </div>
                                    </div>
                                </div>
                            )})
                        }

                    </>
                }
                {
                    localIsMobile && localQuotes.length &&
                    <div className="quotesContainer">
                        {
                            localQuotes.map((item,index)=>{return(
                                <Link className="mobileQuotePreview" to={`/profile/quotes-history/${item.id}`} key={`quote${index}`}>
                                    <h4>Quote {localQuotes.length - index}</h4>
                                    <p className="quoteDate">{item.date}</p>
                                    <div className="newBestOfferWrapper">
                                        <p className="newBestOffer">New Best Offer</p>
                                    </div>
                                    <p className="currentQuotesCarList">
                                    {item.vehicles.map((vehicleItem, vehicleIndex) => {
                                      return (
                                        <span key={`vehicle${vehicleIndex}`}>
                                          {`${vehicleItem.make} `}
                                        </span>
                                      )
                                    })
                                    }
                                    </p>
                                    <img className="viewQuoteDetailArrow" src={RightArrowIcon} alt="rightArrow"/>
                                </Link>
                            )})
                        }
                    </div>
                }
                <div className="quoteBtnContainer">
                    <button onClick={() => {
                        updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/'})
                    }} className="quoteBtn">Get a New Quote
                    </button>
                </div>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
        if (prevProps.quoteItems.count !== nextProps.quoteItems.count) {
            return false
        } else {
            return true
        }
    }
);

export default QuoteList;
