import {useContext, useEffect, useReducer} from "react";
import axios from "axios";
import httpRequest from "../../services/httpRequest";

import {QUOTE_FORM_API, requestStatusTypes} from "../../config/constants";

//contexts
import GlobalDataContext from "../../contexts/GlobalDataContext";
import ViewContext from "../../contexts/ViewContext";
import {viewLoaderActionTypes} from "../../contexts/ViewContext/viewContextActions";

//locals
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";
import {postalCodeFormat} from "../../helpers/postalCodeFormat";




const PostalDataManager = () => {

    const {updateGlobalData} = useContext(GlobalDataContext);
    const {updateViewLoader} = useContext(ViewContext);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: [],
        cancelTokenSource: axios.CancelToken.source()
    });

    function checkFromForm(postal){
        if(postal.length===6){
            return ''
        }
        return 'Please enter a valid postal code'
    }

    const getPostal = async (postalCode, fromForm, postalOriginal) => {
        let errorMSG = '';
        updateGlobalData({type: globalDataActionTypes.UpdatePostalErrors, payload: ''});

        if(fromForm){
            errorMSG = checkFromForm(postalCodeFormat(postalCode));
        }

        const bodyPostal = postalCodeFormat(postalCode);

        if(errorMSG==='') {
            updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
            updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});

            const response = await httpRequest(`${QUOTE_FORM_API.GET_POSTAL}?postal_code=${bodyPostal}`, {
                method: 'get',
                body: {}
            });
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    updateGlobalData({type: globalDataActionTypes.UpdatePostal, payload: {
                            ...response.data,
                            postal_code: response.data.postal_code,
                        }});
                    updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
                } else {
                    if (response.status !== 418) {
                        updateGlobalData({type: globalDataActionTypes.ClearPostal});
                    }
                    updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
                    updateGlobalData({type: globalDataActionTypes.UpdatePostalErrors, payload: response.message});
                    updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
                }
            }
        }
        else{
            updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: errorMSG});
            updateGlobalData({type: globalDataActionTypes.UpdatePostalErrors, payload: errorMSG});
            updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
        }
    }

    useEffect(() => {
        return () => {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return{
        postalRequestState: localState.requestState,
        postalResponseData: localState.responseData,
        postalSuccessMessage: localState.SuccessMessage,
        postalRequestError: localState.errorMessage,
        getPostal
    }
}
export default PostalDataManager;
