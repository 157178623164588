import React, {useContext} from "react";
import ViewContext from "../../../contexts/ViewContext";
import {viewRedirectActionTypes} from "../../../contexts/ViewContext/viewContextActions";

import AddDriverButton from './AddDriverButton';
import AddVehicleDriverButton from "./AddVehicleButton";


const FormBottomNavigationButtons = (props) => {

    const {updateViewRedirect} = useContext(ViewContext);

    return (
        <>

            {
                props.isAddVehicle &&
                <AddVehicleDriverButton />
            }
            {
                props.isAddDriver &&
                <AddDriverButton />
            }
            <div className="bottom-navigation-btns-wrapper">
                {
                    props.prev && props.prev!=='' &&
                    <button onClick={()=>{updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: props.prev})}} className="bottom-previous-btn">
                        Previous
                    </button>
                }
                {
                    props.next && props.next!=='' &&
                    <button onClick={()=>{updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: props.next})}} className="bottom-next-btn">
                        Next
                    </button>
                }

            </div>
        </>
    );
};
export default FormBottomNavigationButtons;
