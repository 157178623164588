import React, {useCallback, useContext, useEffect, useReducer} from "react";
import GlobalDataContext from "../../../contexts/GlobalDataContext";
import {QuoteDataManager} from "../../../managers/DataManagers/QuoteDataManager";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {formStateActionTypes, formStateReducer} from "../../../helpers/formState";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import DiscountDataContext, {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import ViewContext from "../../../contexts/ViewContext";
import {AuthDataManager} from "../../../managers/DataManagers";
import SingUpValidationManager from "../../../managers/ValidationManagers/SignUpValidationManager";
import ReactTooltip from "react-tooltip";
import {requestStatusTypes} from "../../../config/constants";
import InputLicence from "./components/InputLicence";
import InputCheckBox from "../../ui/Inputs/InputCheckBox";
import InputEmailWithValidation from "../../ui/Inputs/InputEmailWithValidation";
import PhoneInput from "../../ui/Inputs/PhoneInput";

const FormThreeWrapper = () => {

    const {globalData} = useContext(GlobalDataContext);
    const {discountData, updateDiscountData} = useContext(DiscountDataContext);
    const {vehicleData} = useContext(VehicleDataContext);
    const {driverData, driverTab} = useContext(DriverDataContext);
    const [localState, updateLocalState] = useReducer(formStateReducer, {});
    const {updateViewModal, updateViewRedirect} = useContext(ViewContext);
    const {quoteRequestState, quoteErrorMessage, postRequestQuote} = QuoteDataManager();
    const {authRequestState, authRequestError, signUp} = AuthDataManager();
    const {signUpValidationStepThree} = SingUpValidationManager();
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState});

    const handleInput = useCallback((event, rootName)=>{
        if (rootName) {
            updateLocalState({
                type: formStateActionTypes.Update,
                payload: {
                    ...localState,
                    [rootName]: {...localState[rootName], [event.target.name]: event.target.value}
                }
            });
        } else {
            updateLocalState({
                type: formStateActionTypes.Update,
                payload: {...localState, [event.target.name]: event.target.value}
            });
        }
    }, [localState]);

    function handleSwitch(name, value) {
        updateLocalState({type: formStateActionTypes.Update, payload: {...localState, [name]: value}})
    }

    function handleDriverOption(event, vehicleIndex) {
        let newVehlinks = [...discountData.vehLink];
        newVehlinks[vehicleIndex] = {
            ...newVehlinks[vehicleIndex], driver_index: Number(event.target.value),
        }
        updateDiscountData({type: discountDataActionTypes.UpdateVehlinks, payload: [...newVehlinks]});
    }

    function handleQuotes(){
        postRequestQuote(true, false, true, true);
    }

    function handleCreateAccount(event){
        const signUpErrors = signUpValidationStepThree();
        if(signUpErrors.length>0){
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: signUpErrors[0],
                description: '',
                submitPath: ''
            });
        }
        else{
            let newUser = {...discountData.newUser};
            if(driverData.items[0].first_name!==newUser.first_name){
                delete newUser.dob;
                delete newUser.occupation;
                delete newUser.marital_status;
            }
            signUp(newUser, true);
        }
    }

    useEffect(() => {
        if (renderState.isRender) {
            if(localState!==discountData){
                updateDiscountData({type: discountDataActionTypes.UpdateDiscountData, payload:localState});
            }

        }
    }, [renderState, localState]);

    useEffect(() => {
        if (quoteErrorMessage) {
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: quoteErrorMessage,
                description: '',
                submitPath: ''
            });
        }
    }, [quoteErrorMessage, quoteRequestState]);

    useEffect(() => {
        if (renderState.isFirst) {
            updateLocalState({type: formStateActionTypes.Update, payload: discountData});
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
        }
    });

    useEffect(() => {
        if (quoteRequestState === requestStatusTypes.Success) {
            updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/quote'});
        }
    }, [quoteRequestState]);


    return (
        <div className="discountSecondStep">
            {
                renderState.isRender &&
                <>
                    <div className="discountSecondStepTopRow">
                        <h5>You are just seconds away from the best quote</h5>
                    </div>


                    <div className='discountSecondStepBottomRow'>
                        <h4 className="stepIntroTitle">You are seconds away from receiving your car insurance quotes,
                            please provide your email after completing the discount section so we can send you your
                            personalized free car insurance quotes!</h4>

                        {
                            vehicleData.items.length > 1 && driverTab.tabs.length > 1 &&
                            <>
                                <h4 className="stepIntroTitle"> Since there are multiple drivers in this quote, please
                                    tell us who drives which vehicle the most. Please provide an answer that best suits
                                    your situation. </h4>

                                {
                                    vehicleData.items.map((vehicleItem, vehicleIndex) => {
                                        return (
                                            <div key={`vehicleItem${vehicleIndex}`}>
                                                <div className="formLabelAndTooltipWrapper">
                                                    <h4>Who is the principal driver of
                                                        the {vehicleItem.year} {vehicleItem.make} {vehicleItem.model} ?</h4>
                                                </div>
                                                <select
                                                    className='formSelect'
                                                    onChange={(event) => {
                                                        handleDriverOption(event, vehicleIndex)
                                                    }}
                                                    defaultValue={discountData.vehLink[vehicleIndex].driver_index}
                                                >
                                                    <option value='' disabled={true}>Select driver</option>
                                                    {
                                                        driverTab.tabs.map((driverTab, driverTabIndex) => {
                                                            return (
                                                                <option
                                                                    key={`driverOption${driverTabIndex}`}
                                                                    value={driverTab.value}
                                                                >
                                                                    {
                                                                        driverTab.name
                                                                    }
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        )
                                    })

                                }
                            </>
                        }

                        <div className="formLabelAndTooltipWrapper">
                            <h4>You could receive a 10-15% discount for bundling insurance with multiple policies with
                                the same insurance company. Does that interest you?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='multipleDiscount'/>
                            <ReactTooltip id='multipleDiscount' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Bundling your home and auto insurance can save you
                                    significantly on insurance premiums as a whole package. Do you want to learn more
                                    about the benefits of multiple policies for home, tenant, condo, or car insurance?
                                    All you have to do is select yes..</p>
                            </ReactTooltip>
                        </div>

                        <div className="formButtonsWrapper">
                            <button
                                onClick={() => handleSwitch('multiple_policies_discount', true)}
                                className={localState.multiple_policies_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
                            </button>
                            <button
                                onClick={() => handleSwitch('multiple_policies_discount', false)}
                                className={localState.multiple_policies_discount === false ? "selectedBtn" : "deSelectedBtn"}>No
                            </button>
                        </div>

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Please indicate if you are a member of CAA, <br/> you could save up to an additional
                                20%.</h4>
                        </div>

                        <div className="formButtonsWrapper">
                            <button
                                onClick={() => handleSwitch('caa_member_discount', true)}
                                className={localState.caa_member_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
                            </button>
                            <button
                                onClick={() => handleSwitch('caa_member_discount', false)}
                                className={localState.caa_member_discount === false? "selectedBtn" : "deSelectedBtn"}>No
                            </button>
                        </div>


                        <div className="formLabelAndTooltipWrapper">
                            <h4>You could receive up to a 30% discount by <br/> using an app to track driving
                                habits. <br/> Does that interest you?</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='appInstalled'/>
                            <ReactTooltip id='appInstalled' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Get rewarded for safe driving with the click of a button. If
                                    you install the app, you may be eligible for a 30% discount on your insurance
                                    premiums. Typically, you receive 10% of the discount upfront and the balance after
                                    six months of good driving. </p>
                            </ReactTooltip>
                        </div>

                        <div className="formButtonsWrapper">
                            <button
                                onClick={() => handleSwitch('app_install_discount', true)}
                                className={localState.app_install_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
                            </button>
                            <button
                                onClick={() => handleSwitch('app_install_discount', false)}
                                className={localState.app_install_discount === false ? "selectedBtn" : "deSelectedBtn"}>No
                            </button>
                        </div>

                        <div className="stepIntroTitle">
                            <div>Complete this form to see all your personalized </div>
                            <div>quotes on the next page, you will also be able to: </div>
                        </div>
                        <div className="stepIntroTitle fonts-regular">
                            <p>1. See what rates insurance carriers are offering </p>
                            <p>2. Get in touch with us and secure your rate </p>
                            <p>3. Potentially save more by speaking with a broker </p>
                        </div>

                        <div className="formLabelAndTooltipWrapper">
                            <h4>First Name</h4>
                        </div>

                        <input
                            className="formInput"
                            placeholder="Your First Name"
                            name='first_name'
                            defaultValue={localState.quoter_info.first_name}
                            onChange={(event) => {
                                handleInput(event,'quoter_info');
                            }}
                        />

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Last Name</h4>
                        </div>

                        <input
                            className="formInput"
                            placeholder="Your Last Name"
                            name='last_name'
                            defaultValue={localState.quoter_info.last_name}
                            onChange={(event) => {
                                handleInput(event,'quoter_info');
                            }}
                        />

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Driver Licence Number (Optional)</h4>
                            <div className="tooltipIcon" data-tip={true} data-for='driverLicenceForQuotes'/>
                            <ReactTooltip id='driverLicenceForQuotes' className="tooltipWrapper" arrowColor="transparent">
                                <p className="tooltipText">Enter your drivers licence number in to receive a more accurate, prequalified quote from our broker partners. This will enable you to provide less details over the phone if you choose to have a broker contact you. This is an optional input.</p>
                            </ReactTooltip>
                        </div>

                        <InputLicence
                            placeholder="V1234-12345-12345"
                            classNames="formInput"
                            name="driver_license"
                            maxLength={17}
                            rootName="quoter_info"
                            defaultValue={localState.quoter_info.driver_license}
                            onChange={handleInput}
                        />

                        <div className="formLabelAndTooltipWrapper">
                            <h4>Phone Number</h4>
                        </div>

                        <PhoneInput
                            name='phone'
                            defaultValue={localState.quoter_info.phone}
                            onChange={(event) => {
                                handleInput(event, 'quoter_info');
                            }}
                        />

                        <InputEmailWithValidation
                            label={{
                                title: "Please provide your email address so we \n can send you a copy of your quotes",
                                id: "main-email-address",
                                description: "We will provide you with your insurance quotes immediately \n after this form completion. If you are not ready to apply today, don’t worry as we\n will be emailing your quotes\n to you if you’d like to complete at a later date with the help of one of our trusted \n broker partners.",
                            }}
                            className="formInput"
                            name="email_to"
                            defaultValue={localState.email_to}
                            onChange={(event) => {
                                handleInput(event)
                            }}
                        />


                        {
                            /*
                            <div className="checkbox">
                            <input
                                type="checkbox"
                                name="isAlert"
                                checked={localState.isAlert}
                                value={localState.isAlert ? 'off' : 'on'}
                                onChange={(event) => handleCheckBox(event)}
                            />
                            <label><span><b>PRICE DROP ALERTS:</b> Didn’t find the rate you were looking for? Since we
                              know we have the best rates around, use our smart technology to shop
                              around automatically while you sleep. We’ll alert you when we get a better
                              rate that matches your profile!</span></label>
                        </div>
                        */
                        }

                        {
                            localState.isAlert &&
                            <div className="priceDropAlertFields">

                                <div className="formLabelAndTooltipWrapper">
                                    <h4>Current Insurance Provider</h4>
                                </div>
                                <input
                                    className="formInput"
                                    placeholder="Your Current Insurance Provider"
                                    defaultValue={localState.alert.current_company}
                                    name="current_company"
                                    onChange={(event) => {
                                        handleInput(event,'alert');
                                    }}
                                />

                                <div className="formLabelAndTooltipWrapper">
                                    <h4>Current Monthly Payments</h4>
                                </div>
                                <input
                                    type='number'
                                    className="formInput"
                                    placeholder="Your Current Monthly Payments"
                                    defaultValue={localState.alert.current_price_monthly}
                                    name="current_price_monthly"
                                    onChange={(event) => {
                                        handleInput(event,'alert');
                                    }}
                                />

                                {
                                    !globalData.isAuth &&
                                    <>
                                        <div className="formLabelAndTooltipWrapper">
                                            <h4>Gender</h4>
                                            <div className="tooltipIcon" data-tip={true} data-for='selectGender'/>
                                            <ReactTooltip id='selectGender' className="tooltipWrapper"
                                                          arrowColor="transparent">
                                                <p className="tooltipText">The gender on the policy should match your official driver’s licence. Some insurers analyze a driver's sex when creating a policy. Men are typically considered higher risk than female drivers, but the statistics supporting this idea vary from province to province. On average, men and women pay roughly the same for insurance, though.</p>
                                            </ReactTooltip>
                                        </div>
                                        <div className="formButtonsWrapper">
                                            <button
                                                onClick={() => handleSwitch('gender', 'M', 'newUser')}
                                                className={`${localState.newUser.gender === 'Male' || localState.newUser.gender === 'M' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                                                Male
                                            </button>
                                            <button
                                                onClick={() => handleSwitch('gender', 'F', 'newUser')}
                                                className={`${localState.newUser.gender === 'Female' || localState.newUser.gender === 'F' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                                                Female
                                            </button>
                                            <button
                                                onClick={() => handleSwitch('gender', 'other', 'newUser')}
                                                className={`${localState.newUser.gender === 'Other' || localState.newUser.gender === 'other' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                                                Other
                                            </button>
                                        </div>
                                        <form autoComplete="off">
                                            <div className="formLabelAndTooltipWrapper">
                                                <h4>Choose Password For Your Account</h4>
                                                <div className="tooltipIcon" data-tip={true} data-for='accountPass'/>
                                                <ReactTooltip id='accountPass' className="tooltipWrapper"
                                                              arrowColor="transparent">
                                                    <p className="tooltipText">Password must be at least 8 characters long
                                                        and include 1 number</p>
                                                </ReactTooltip>
                                            </div>
                                            <input
                                                autoComplete="off"
                                                className="formInput"
                                                type="password"
                                                placeholder="Enter Your Password"
                                                defaultValue={localState.newUser.password1}
                                                name="password1"
                                                onChange={(event) => {
                                                    handleInput(event,'newUser');
                                                }}
                                            />

                                            <div className="formLabelAndTooltipWrapper">
                                                <h4>Confirm Password</h4>
                                            </div>
                                            <input
                                                autoComplete="off"
                                                className="formInput"
                                                type="password"
                                                placeholder="Confirm Your Password"
                                                defaultValue={localState.newUser.password2}
                                                name="password2"
                                                onChange={(event) => {
                                                    handleInput(event,'newUser');
                                                }}
                                            />
                                        </form>
                                    </>
                                }

                            </div>
                        }
                        <InputCheckBox
                            onChange={handleInput}
                            checked={localState.quoter_info.casl_consent}
                            name="casl_consent"
                            rootName="quoter_info"
                            description="Yes, I consent to receiving emails from the Billyard Insurance Group.  I understand that I can unsubscribe at any time"
                        />
                        {
                            authRequestError &&
                            <span className='errorMsg'>{authRequestError}</span>
                        }

                        <div className="formButtonsWrapper">
                            {
                                globalData.isAuth &&
                                <button className="selectedBtn selectedFullWidthBtn" onClick={() => {
                                    handleQuotes();
                                }}>
                                    Get Your Quotes
                                </button>
                            }
                            {
                                !localState.isAlert && !globalData.isAuth &&
                                <button className="selectedBtn selectedFullWidthBtn" onClick={() => {
                                    handleQuotes();
                                }}>
                                    Get Your Quotes
                                </button>
                            }
                            {
                                localState.isAlert && !globalData.isAuth &&
                                <>
                                    <button className="selectedBtn selectedFullWidthBtn" onClick={(event)=>{
                                        if(authRequestState!==requestStatusTypes.Loading) {
                                            handleCreateAccount(event);
                                        }
                                    }}>{
                                        authRequestState!==requestStatusTypes.Loading ? 'Create Account' : 'Loading... '
                                    }
                                    </button>
                                </>

                            }
                        </div>

                        <div className="stepEndDescription">
                            <p>
                                <span>DISCLAIMER:</span> As insurance premiums are based on the information you
                                provide it is important that it be 100% accurate and up to date. If you
                                are not sure it is best to check before completing your quote.
                            </p>
                        </div>

                        {
                            /*
                            !globalData.isAuth &&
                            <p className="alreadyMemberHintText"> Already have an Account?
                                <span onClick={() => {
                                    updateGlobalData({
                                        type: globalDataActionTypes.UpdateSignedInRedirect,
                                        payload: 'thirdFormSecondPart'
                                    });
                                    updateViewRedirect({
                                        type: viewRedirectActionTypes.Redirect,
                                        payload: '/accounts/signin'
                                    });
                                    updateViewModal({type: viewModalActionTypes.ClearModal});
                                }}>Log In </span>
                            </p>*/
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default FormThreeWrapper;
