import React from "react";
import ReactTooltip from "react-tooltip";
import activeImage from '../../../../resources/images/endorsement-checked-active.svg';

const EndorsementRow = (props) => (
    <div className={`formEndorsementLabelAndTooltipWrapper ${props.isActive ? '' : 'inactive'}`}>
        <h4>

            {
                props.isActive &&
                <img src={activeImage} alt='active-endorsement'/>
            }
            {props.title}
        </h4>
        <div className="tooltipIcon" data-tip={true} data-for={`thirdPartyLiability${props.id}`}/>
        <ReactTooltip id={`thirdPartyLiability${props.id}`} className="tooltipWrapper"
                      arrowColor="transparent">
            <p className="tooltipText">{props.toolTip}</p>
        </ReactTooltip>
    </div>
);

export default EndorsementRow;
