import React, {useContext} from "react";
import ViewContext from "../../../contexts/ViewContext";
import {PrimaryLoader} from "../../ui/icons";

const ContentLoader = () => {

    const {viewLoader} = useContext(ViewContext);

    return (
        <div className='content-loader' style={{opacity:viewLoader.isTransparent ? 0 : 1}}>
            <div className='loader-wrapper'>
                <h4>{viewLoader.message}</h4>
                <PrimaryLoader />
            </div>
        </div>
    );
};

export default ContentLoader;
