import React, {useCallback, useContext, useEffect, useState} from "react";
import DriverDataContext from "../../../../../../../contexts/formContexts/DriverDataContext";
import {formLicenceTypes} from "../../../../../../../config/formConstants";
import {driverDataActionTypes} from "../../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {FormConfigStateContext} from "../../../../../../../contexts/formContexts/FormConfigState";

const GLicenceSelect = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);

    const [localProvinceCode, setLocalProvinceCode] = useState(formConfig.code);
    const [licenceOptions, setLicenceOptions] = useState([]);
    const [localMinDates, setLocalMinDates] = useState({});

    const handleEvent = useCallback((event) => {
        event.persist();
        updateDriverData({
            type:driverDataActionTypes.UpdateLicenceType,
            payload: event.currentTarget.value,
            index: driverTab.activeItem,
            config: formConfig.minDates,
        });
    },[]);

    useEffect(()=>{
        if(driverData.items[driverTab.activeItem].minDates !== localMinDates
            || (
                licenceOptions[0].name !== formConfig.licenceConfig.g.title
                || licenceOptions[1].name !== formConfig.licenceConfig.g1.title
                || licenceOptions[2].name !== formConfig.licenceConfig.g2.title
            )) {
            setLicenceOptions([
                {
                    value: formLicenceTypes.g,
                    disabled: driverData.items[driverTab.activeItem].minDates.gBlocked,
                    name: formConfig.licenceConfig.g.title,
                },
                {
                    value: formLicenceTypes.gOne,
                    disabled: driverData.items[driverTab.activeItem].minDates.gOneBlocked,
                    name: formConfig.licenceConfig.g1.title,
                },
                {
                    value: formLicenceTypes.gTwo,
                    disabled: driverData.items[driverTab.activeItem].minDates.gTwoBlocked,
                    name: formConfig.licenceConfig.g2.title,
                }
            ]);
            setLocalMinDates({...driverData.items[driverTab.activeItem].minDates});
        }
    },[driverTab.activeItem, driverData.items[driverTab.activeItem].minDates, localProvinceCode]);

    useEffect(() => {
        if (localProvinceCode !== formConfig.code) {
            setLocalProvinceCode(formConfig.code);
        }
    },[formConfig.code]);

    return(
        <select
            name="licence_type"
            value={driverData.items[driverTab.activeItem].licence_info.licence_type}
            onChange={(event) => {
                handleEvent(event);
            }}
            className='formSelect'
            disabled={!driverData.items[driverTab.activeItem].date_of_birth}
        >
            <option value='' disabled={true}>Select from the List</option>
            <>
                {
                    licenceOptions.map((item, index) => {
                        return <option key={`glicenceoption${index}`} value={item.value}
                                       disabled={item.disabled}>{item.name}</option>
                    })
                }
            </>
        </select>
    );
}

export default GLicenceSelect;
