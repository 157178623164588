import {modalTypes} from "../../components/layout/modals/ModalWrapper/constants";

export const viewLoaderInitialState = {
    isOpen: true,
    message: '',
    isTransparent: false,
}


export const viewSizeInitialState = {
    width: 0,
    height: 0,
    isMobile: false,
};

export const viewModalInitialState = {
    isOpen: false,
    type: modalTypes.Message,
    title: '',
    description: '',
    isHaveToSignOut: false,
    submitPath: '',
    actions:{
        callback: '',
        id: 0,
        state: '',
        submit: ''
    }
};

export const viewRedirectInitialState = {
    redirectPath: '',
    isRedirect: false,
}

export const viewPageInitialState = {
    message: '',
    quoteRequest: false
};

export const viewHeaderInitialState = {
    isMobileOpen: false,
    isDesktopOpen: false,
    vehicleNavigation: false,
    vehicleId: -1
};

export const viewContentInitialState = {
    isLoader: false
};
