import {useContext, useEffect, useReducer} from "react";

/* constants */
import {PROFILE_API, requestStatusTypes} from "../../config/constants";

import axios from "axios";
import httpRequest from "../../services/httpRequest";

// context
import GlobalDataContext from "../../contexts/GlobalDataContext";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";

import ViewContext from "../../contexts/ViewContext/ViewContext";
import {
    viewLoaderActionTypes,
    viewModalActionTypes,
    viewRedirectActionTypes
} from "../../contexts/ViewContext/viewContextActions";


/* locals */
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";
import {AuthDataManager} from "./AuthDataManager";


export const UserDataManager = () => {

    const {updateGlobalData} = useContext(GlobalDataContext);

    const {updateViewModal, updateViewLoader, updateViewRedirect} = useContext(ViewContext);

    const {signOut} = AuthDataManager();

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: {},
        cancelTokenSource: axios.CancelToken.source()
    });

    function defaultLoadingState() {
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader,payload: true});
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
    }

    function defaultErrorState(response) {
        if(response.action===requestStatusTypes.UserSignOut){
            signOut();
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader,payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
    }

    function defaultSuccessState(response, specialMessage) {
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({
            type: DataManagerActionTypes.UpdateSuccessMessage,
            payload: specialMessage ? specialMessage : response.message
        });
        updateLocalState({type: DataManagerActionTypes.ResponseState, payload: response.data});
        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader,payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
    }

    const getUserData = async () => {

        defaultLoadingState();

        const response = await httpRequest(PROFILE_API.USER, {method: 'get'}, localState.cancelTokenSource.token);

        if (response) {

            if (response.status === 200 || response.status === 201) {

                defaultSuccessState(response);
            } else {

                defaultErrorState(response);
            }
        }
    };

    const updateUserData = async (data) => {
        const rData = data;

        defaultLoadingState();

        const response = await httpRequest(PROFILE_API.USER, {
            method: 'put',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);

        if (response) {
            if (response.status === 200 || response.status === 201) {
                updateGlobalData({
                    type: globalDataActionTypes.UpdateUser, data: rData
                });
                defaultSuccessState(response, 'Profile updated');
                updateViewModal({
                    type: viewModalActionTypes.OpenMessageModal,
                    title: 'You have Successfully updated your profile information',
                    description: '',
                    submitPath:''
                });
                updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/profile/view'});

            } else {
                defaultErrorState(response);
            }
        }
    };

    const userPasswordUpdate = async (data) => {

        defaultLoadingState();

        const response = await httpRequest(PROFILE_API.POST_PASSWORD_CHANGE, {
            method: 'post',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);

        if (response) {
            if (response.status === 200 || response.status === 201) {

                defaultSuccessState(response)
                updateViewModal({
                    type: viewModalActionTypes.OpenMessageModal,
                    title: 'Your password had been Successfully changed',
                    description: '',
                    submitPath:''
                });
                updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/profile/view'});
            } else {
                defaultErrorState(response);
            }
        }
    };

    useEffect(() => {
        return () => {
            updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        userRequestState: localState.requestState,
        userResponseData: localState.responseData,
        userSuccessMessage: localState.SuccessMessage,
        userErrorMessage: localState.errorMessage,
        getUserData,
        updateUserData,
        userPasswordUpdate
    });
};
