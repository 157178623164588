import React, {useContext, useEffect, useReducer} from 'react';

import VehicleDataContext from "../../contexts/formContexts/VehicleDataContext";
import DriverDataContext from "../../contexts/formContexts/DriverDataContext";
import DiscountDataContext, {discountDataActionTypes} from "../../contexts/formContexts/DiscountDataContext";
import {vehicleDataActionTypes} from "../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import ViewContext from "../../contexts/ViewContext";
import {viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../helpers/hooksLocalRenderState";
import {driverDataActionTypes} from "../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import QuoteDataContext from "../../contexts/formContexts/QuoteDataContext";
import {quoteDataActionTypes} from "../../contexts/formContexts/QuoteDataContext/quoteDataActions";


const VehicleClearForm = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);

    const {updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);

    const {updateDriverData, updateDriverTab} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);
    const {updateQuoteData} = useContext(QuoteDataContext);
    const {updateViewRedirect} = useContext(ViewContext);

    function clearAllForms(){
        updateVehicleData({type:vehicleDataActionTypes.Clear});
        updateVehicleTab({type:vehicleDataActionTypes.Clear});
        updateDriverTab({type:driverDataActionTypes.Clear});
        updateDriverData({type:driverDataActionTypes.Clear});
        updateDiscountData({type:discountDataActionTypes.Clear});
        updateQuoteData({type:quoteDataActionTypes.ClearQuote});
    }

    useEffect(()=>{
        if(!renderState.isFirst){
            updateViewRedirect({type:viewRedirectActionTypes.Redirect,payload:''});
        }
    },[renderState]);


    useEffect(()=>{
            if(renderState.isFirst){
                clearAllForms();
                updateRenderState({type:renderStateActionTypes.UpdateFirst,payload:false});
            }
        })

    return(
        <>
        </>
    )
}

export default VehicleClearForm;
