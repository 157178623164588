import React from "react";

const ButtonLoader = () => (
    <div className="loading">
        <div/>
        <div/>
        <div/>
    </div>
);

export default ButtonLoader;
