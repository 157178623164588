export const driverTabItemInitialState = {
    name: 'Driver 1',
    value: 0
};

export const driverLicenceInitialState = {
    licence_type: "",
    first_licence_age: 16,
    previous_licence: false,
    g_licence_date: '',
    g1_licence_date: '',
    g2_licence_date: '',
    passed_driver_training: false,
    isOnlyG: false
}

export const driverVehlinkInitialState = {
    vehicle_index: "",
    model: "",
    priority: "Prn"
}

export const minDateInitialState = {
    gOneMinDate: '',
    gOneMaxDate: '',
    gOneBlocked: true,
    gOneDefaultValue: '',
    gTwoMinDate: '',
    gTwoMaxDate: '',
    gTwoBlocked: true,
    gTwoDefaultValue: '',
    gMinDate: '',
    gMaxDate: '',
    gBlocked: true,
    gDefaultValue: '',
    maxLicenceAge: 16
}

export const driverDataItemInitialState = {
    first_name: "",
    date_of_birth: "",
    marital_status: "S",
    gender: "M",
    occupation: 998,
    licence_info: {...driverLicenceInitialState},
    applicant_relationship: "",
    listed: true,
    listed_year: "",
    insured: true,
    insured_year: "",
    insurance_cancellation: false,
    insurance_cancellation_list: [{reason: '', end_date: '', start_date: ''}],
    licence_suspension: false,
    licence_suspension_list: [{reason: '', reinstatement_date: '', suspension_date: ''}],
    accident: false,
    accident_list: [{accident_date: ''}],
    ticket: false,
    ticket_list: [{reason: '', ticket_date: ''}],
    vehlink: {...driverVehlinkInitialState},
    minDates: {...minDateInitialState}
};
