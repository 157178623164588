import React, {useContext} from "react";
import VehicleInputTitleWithToolTip from "../../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import DriverDataContext from "../../../../../../../contexts/formContexts/DriverDataContext";
import GLicenceSelect from "./GLicenceSelect";
import GLicenceBlock from "./GLicenceBlock";
import {FormConfigStateContext} from "../../../../../../../contexts/formContexts/FormConfigState";

const DriverGLicence = () => {

    const {driverTab, driverData} = useContext(DriverDataContext);

    const {formConfig} = useContext(FormConfigStateContext);

    return(
      <>
          <VehicleInputTitleWithToolTip
            id="licenceType"
            title={
                `Please choose the type of licence that \n ${driverData.items[driverTab.activeItem].first_name} currently holds`
            }
            description={formConfig.toolTip.licenceType}
          />
          <GLicenceSelect />
          <GLicenceBlock />
      </>
    );
}

export default DriverGLicence;
