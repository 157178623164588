import React from 'react';
import {Route, Switch} from 'react-router-dom';


/* route types */
import PrivateRoute from './routeTypes/PrivateRoute';
import AuthRoute from "./routeTypes/AuthRoute";

/* PAGES */

/* AUTH pages*/
import SignUpForm from '../components/authForms/SignUpForm';
import SignInForm from '../components/authForms/SignInForm';
import EmailVerification from "../components/authForms/EmailVerification";
import PasswordForgot from '../components/authForms/PasswordForgot';
import PasswordReset from "../components/authForms/PasswordReset";


/*profile pages*/
import {MyProfileEdit, MyProfileView, PasswordChange} from '../components/MyProfile';
import PostalCode from "./PostalCode";
import ErrorPage from "../views/ErrorPage";
import Quotes from "../components/Quotes";

// form steps
import FormOne from "../components/vehicleForms/FormOne";
import FormTwo from "../components/vehicleForms/FormTwo";
import FormThree from "../components/vehicleForms/FormThree";
import FormFour from "../components/vehicleForms/FormFour";
import VehicleClearForm from "../components/vehicleForms/VehicleClearForm";
import BrokerKeys from "./BrokerKeys";


export const AppRoutes = () => (
    <Switch>
        <AuthRoute exact path='/accounts/signin'>
            <SignInForm/>
        </AuthRoute>

        <AuthRoute exact path='/accounts/email/verification/:emailKey'>
            <EmailVerification/>
        </AuthRoute>

        <AuthRoute exact path='/accounts/signup'>
            <SignUpForm/>
        </AuthRoute>

        <AuthRoute exact path='/accounts/password/forgot'>
            <PasswordForgot/>
        </AuthRoute>

        <AuthRoute exact path='/accounts/password/reset/:localUID/:localTOKEN'>
            <PasswordReset/>
        </AuthRoute>

        <PrivateRoute exact path='/profile/view'>
            <MyProfileView/>
        </PrivateRoute>

        <PrivateRoute exact path='/profile/edit'>
            <MyProfileEdit/>
        </PrivateRoute>

        <PrivateRoute exact path='/profile/password/change'>
            <PasswordChange/>
        </PrivateRoute>

        <PrivateRoute path="/profile/quotes-history">
            <Quotes/>
        </PrivateRoute>

        <Route exact path="/vehicle/form/clear">
            <VehicleClearForm/>
        </Route>

        <Route exact path="/quote">
            <FormFour/>
        </Route>

        <Route exact path="/info">
            <FormThree/>
        </Route>

        <Route exact path="/driver">
            <FormTwo/>
        </Route>

        <Route exact path="/">
            <FormOne/>
        </Route>

        <Route exact path='/postal'>
            <PostalCode/>
        </Route>

        <Route exact path='/broker'>
            <BrokerKeys/>
        </Route>

        <Route path='*' exact component={() => <ErrorPage/>}/>
    </Switch>
);

export default AppRoutes;
