import React, {useContext, useEffect, useReducer} from "react";

import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";

import StepOneValidationManager from "../../../managers/ValidationManagers/StepOneValidationManager";
import StepTwoValidationManager from "../../../managers/ValidationManagers/StepTwoValidationManager";
import {validationStatusTypes} from "../../../managers/ValidationManagers/constants";
import GlobalDataContext from "../../../contexts/GlobalDataContext";
import DiscountDataContext, {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext";
import FormThreeWrapper from "./FormThreeWrapper";


const FormThree = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount:2})

    const {globalData} = useContext(GlobalDataContext);
    const {discountData, updateDiscountData} = useContext(DiscountDataContext);


    const {stepOneStatus, stepOneValidation} = StepOneValidationManager();
    const {stepTwoStatus, stepTwoValidation} = StepTwoValidationManager();

    useEffect(() => {
        if (!renderState.isFirst && !renderState.isRender) {
            updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
        }
    }, [renderState]);

    useEffect(() => {
        if (renderState.isFirst) {
            if (stepOneStatus === validationStatusTypes.Waiting) {
                stepOneValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Waiting) {
                stepTwoValidation();
            }
            if(globalData.postal.original_postal_code!==discountData.quoter_info.original_postal_code){
                updateDiscountData({type:discountDataActionTypes.UpdateQuoterPostal, payload:globalData.postal.original_postal_code});
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated) {
                updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            }
        }
    })

    return (
        <div className="add-vehicles-content-wrapper">
            <div className="addVehiclesFormContentWrapper">
                {
                    renderState.isRender &&
                    <FormThreeWrapper />
                }
            </div>
        </div>
    )
}
export default FormThree;
