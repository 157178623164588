export const defaultPages = [
    {
        name: 'QUOTER',
        link: '/',
        isVehicleForm: true,
        isLocal: true
    }
];

export const userPublicPages = [
    {
        name: 'LOGIN',
        link: '/accounts/signin',
        className: 'logInLink',
        isLocal: true
    },
    {
        name: 'SIGN UP',
        link: '/accounts/signup',
        className: 'signUpLink',
        isLocal: true
    }
];

export const userPrivatePages = [
    {
        name: 'My Profile',
        link: '/profile/view',
        className: 'userMenuLink',
        isLocal: true
    },
    {
        name: 'Quotes History',
        link: '/profile/quotes-history',
        className: 'userMenuLink',
        isLocal: true
    }
];
