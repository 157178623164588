require('dotenv').config();

const API_ROOT = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL.toString() : '';

export const QUOTE_FORM_API = {
    GET_POSTAL:`${API_ROOT}postal/`,
    GET_VEHICLE:`${API_ROOT}vehicle/year`,
    POST_QUOTE_DATA: `${API_ROOT}quote/`,
    GET_QUOTE_DATA: `${API_ROOT}quote/`,
}

export const AUTH_API = {
    POST_SIGN_IN: `${API_ROOT}login/`,
    GET_SIGN_OUT: `${API_ROOT}logout/`,
    POST_SIGN_UP: `${API_ROOT}registration/`,
    POST_FORGOT: `${API_ROOT}password/reset/`,
    POST_RESET: `${API_ROOT}password/reset/confirm/`,
    POST_EMAIL_VERIFICATION: `${API_ROOT}registration/verify-email/`,
    POST_TOKEN: `${API_ROOT}token/refresh/`,
    POST_EMAIL_VALIDATION: `${API_ROOT}email/validate/`,
};

export const PROFILE_API = {
    USER: `${API_ROOT}user/me/`,
    POST_PASSWORD_CHANGE: `${API_ROOT}password/change/`,
};

//request status types
export const requestStatusTypes = {
    Waiting: 'Waiting',
    Success: 'Success',
    Loading: 'Loading',
    NetworkError: 'NetworkError',
    UserSignOut: 'UserSignOut',
    Error: 'Error',
    Cancel: 'Cancel',
    NoData: 'NoData'
};

// default responses

export const responseCancelState = {
    status: 499,
    data:{},
    message: requestStatusTypes.Cancel
};

export const responseNetworkErrorState = {
    status: 418,
    body: {},
    message: requestStatusTypes.NetworkError
};

export const responseLogoutState = {
    status: 401,
    action: requestStatusTypes.UserSignOut,
    body: {},
    message: requestStatusTypes.UserSignOut
};
