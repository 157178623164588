import React, {useEffect, useState} from "react";
import VehicleInputTitleWithToolTip from "../vehicleFormInputs/VehicInputTitleWithToolTip";
import {AuthDataManager} from "../../../managers/DataManagers";
import {requestStatusTypes} from "../../../config/constants"
import { PrimaryLoader, ValidationApprove, ValidationDecline } from "../icons/";

const InputEmailWithValidation = (props) => {
    const {label, name, className, defaultValue, onChange} = props;
    const {authRequestState, emailValidationError, emailValidation} = AuthDataManager();
    const [localValue, setLocalValue] = useState(defaultValue);
    const [changeValue, setChangeValue] = useState(defaultValue);

    useEffect(()=>{
        if (authRequestState === requestStatusTypes.Success) {
            onChange({target:{
                    name: name,
                    value: localValue,
                }});
        }
        if (authRequestState === requestStatusTypes.Error) {
            onChange({target:{
                    name: name,
                    value: '',
                }});
        }
    },[authRequestState]);

    useEffect(()=>{
        if (defaultValue) {
            emailValidation(defaultValue);
        }
    },[]);

    useEffect(() => {
        const valueTimeout = setTimeout(
            () => {
                if (localValue !== changeValue) {
                    emailValidation(changeValue);
                    setLocalValue(changeValue);
                }
            }, 500
        );
        return () => {
            clearTimeout(valueTimeout);
        }
    }, [localValue, changeValue])

    const localChange = (evt) => {
        onChange({
            target:{
                name: name,
                value: '',
            }
        });
        setChangeValue(evt.target.value);
    }

    return (
        <>
            <VehicleInputTitleWithToolTip
                title={label.title}
                id={label.id}
                description={label.description}
            />
            <div className="email-input-wrapper">
                <input
                    className={`form-input ${className}`}
                    type="email"
                    placeholder='johnsmith@thebig.ca'
                    defaultValue={defaultValue}
                    name={name}
                    onChange={(event) => {
                        localChange(event)
                    }}
                />
                <div className="over-layer">
                    {
                        authRequestState === requestStatusTypes.Loading
                        &&
                        <PrimaryLoader classNames="icon-validate-loader" />
                    }
                    {
                        authRequestState === requestStatusTypes.Error
                        &&
                        <ValidationDecline classNames="icon-validate-decline" />
                    }
                    {
                        authRequestState === requestStatusTypes.Success
                        &&
                        <ValidationApprove classNames="icon-validate-approve" />
                    }
                </div>
                {
                    emailValidationError &&
                    <span className='errorMsg'>{emailValidationError}</span>
                }
            </div>
        </>
    );
};

export default InputEmailWithValidation;
