import axios from 'axios';

export default class {
    getAccessToken = () => {
        return localStorage.getItem('ta');
    };
    getRefreshToken = () => {
        return localStorage.getItem('tr');
    };
    setTokens = (data) => {
        localStorage.setItem('tr', data.refresh);
        localStorage.setItem('ta', data.access);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
    };
    clearTokens = () => {
        localStorage.removeItem('tr');
        localStorage.removeItem('ta');
        delete axios.defaults.headers.common['Authorization'];
    };
}
