import {useContext, useReducer} from "react";

import {validationManagerReducer} from "./validationManagerReducer";
import {validationManagerState} from "./constants";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";


const SingUpValidationManager = () => {

    const [localState] = useReducer(validationManagerReducer, validationManagerState);

    const {discountData} = useContext(DiscountDataContext);

    const signUpValidationStepThree = () => {

        let allErrors = [];

        if(discountData.newUser){
            if(!discountData.newUser.first_name){
                allErrors.push('Please enter your first name');
            }
            if(!discountData.newUser.last_name){
                allErrors.push('Please enter your last name');
            }
            if(!discountData.newUser.dob){
                allErrors.push('Please enter your date of birth');
            }
            if(!discountData.newUser.email){
                allErrors.push('Please enter your email');
            }
            if(!discountData.newUser.password1){
                allErrors.push('Please enter your password');
            }
            else{
                if(discountData.newUser.password1.match(/^(?=.*\d)[0-9a-zA-Z]{8,}$/)){
                    if(!discountData.newUser.password2){
                        allErrors.push('Please enter your confirm password');
                    }
                    else{
                        if(discountData.newUser.password1.match(/^(?=.*\d)[0-9a-zA-Z]{8,}$/)) {
                            if (discountData.newUser.password1 !== discountData.newUser.password2) {
                                allErrors.push('Your password and confirmation password do not match');
                            }
                        }
                        else{
                            allErrors.push('Your password and confirmation password do not match');
                        }
                    }
                }
                else{
                    allErrors.push('Your password must be at least 8 characters and include one number');
                }
            }

        }
        else{
            allErrors.push('Please enter your first name');
        }
        return allErrors;
    };

    return({
        signUpValidationIsValid: localState.isValid,
        signUpValidationStatus: localState.status,
        signUpValidationErrorMessage: localState.errorMessage,
        signUpValidationStepThree: signUpValidationStepThree
    });

}

export default SingUpValidationManager;
