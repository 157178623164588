import React, {useState} from 'react';
import {Link} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';

import {AuthDataManager} from "../../../managers/DataManagers";
import UseForm from "../../../helpers/UseForm";

import InputWrapper from "../../ui/Inputs/InputWrapper";

import {Initial_SignUpFormData} from "./constants";
import ButtonSubmit from "../../ui/buttons/ButtonSubmit";

const SignUpForm = () => {

    const [formData, updateFormData] = useState(cloneDeep(Initial_SignUpFormData));

    const {signUp, authRequestState, authRequestError, authSuccessMessage} = AuthDataManager();

    const {inputHandler, inputBlur, switchHandler, submitHandler, disableFormInput, disableSubmitButton} = UseForm({
        submitHandler: signUp,
        requestState: authRequestState,
        formData: formData,
        updateFormData: updateFormData
    });

    return (
        <div className='addVehiclesFormContentWrapper'>
            <div className='signUpWrapper formWrapper'>
                <h4 className='signUpTitle'>Sign Up</h4>
                <form>
                    {
                        formData.map((item, key) => {
                            return(
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    switchHandler={switchHandler}
                                    inputBlur={inputBlur}
                                />
                            )
                        })
                    }
                    {
                        authRequestError &&
                        <span className='errorMsg'>{authRequestError}</span>
                    }

                    <ButtonSubmit
                        classNames="authSubmitBtn"
                        title="Sign Up"
                        requestState={authRequestState}
                        SuccessMessage={authSuccessMessage}
                        disabled={disableSubmitButton}
                        submitHandler={submitHandler}
                    />

                    <p>Already have an account? <Link className='signUpLogin' to="/accounts/signin">Log In</Link></p>
                </form>
            </div>
        </div>
    );
};

export default SignUpForm;
