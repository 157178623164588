import {DataManagerActionTypes} from './constants';

const dataManagerReducer = (state, action) => {
    switch (action.type) {
        case DataManagerActionTypes.RequestState:
            return {...state, requestState: action.payload};
        case DataManagerActionTypes.ResponseState:
            return {...state, responseData: {...action.payload}};
        case DataManagerActionTypes.UpdateQuoteResponse:
            return {...state, responseData: [...action.payload]};
        case DataManagerActionTypes.UpdateSuccessMessage:
            return {...state, SuccessMessage: action.payload};
        case DataManagerActionTypes.UpdateErrorMessage:
            return {...state, errorMessage: action.payload};
        case DataManagerActionTypes.UpdateManufactureResponse:
            return {...state, carManufactureResponse: action.payload};
        case DataManagerActionTypes.UpdateModelResponse:
            return {...state, carModelResponse: action.payload};

        default:
            return state;
    }
};
export default dataManagerReducer;
