import React, {useContext, useEffect, useReducer} from "react";

import {driverDataReducer} from "./driverDataReducer";
import { driverTabItemInitialState } from './initialValues';

import { getLocalDrivers } from './helper';

import {driverTabReducer} from './driverTabReducer';
import ViewContext from "../../ViewContext";
import {viewPageActionTypes} from "../../ViewContext/viewContextActions";

import {FormConfigStateContext} from "../FormConfigState";

const DriverDataContext = React.createContext();

const driverDataLocal = JSON.parse(localStorage.getItem("driverData"));
const driverTabLocal = JSON.parse(localStorage.getItem("driverTab"));

const driverTabInitialState = {
    activeItem: 0,
    tabs: [{...driverTabItemInitialState}]
}

export const DriverDataContextProvider = (props) => {

    const { formConfig } = useContext(FormConfigStateContext);

    const [driverData, updateDriverData] = useReducer(driverDataReducer, getLocalDrivers(formConfig.licenceConfig.licenceAge, driverDataLocal));

    const [driverTab, updateDriverTab] = useReducer(driverTabReducer, driverTabLocal || driverTabInitialState);

    const {updateViewPage} = useContext(ViewContext);

    useEffect(() => {
        updateViewPage({type: viewPageActionTypes.UpdateQuoteRequest, payload: true});
    }, [driverData, driverTab, updateViewPage]);

    return (
        <DriverDataContext.Provider value={{
            driverData, updateDriverData,
            driverTab, updateDriverTab
        }}>
            {
                props.children
            }
        </DriverDataContext.Provider>
    )
}

export default DriverDataContext;
