import React, {useContext} from "react";
import {NavLink, Link} from "react-router-dom";
import ViewContext from "../../../contexts/ViewContext";

const ButtonHeaderNav = React.memo((props) => {

    const {viewHeader} = useContext(ViewContext);

    return(
      <>
          {
              props.item.isLocal && props.item.isVehicleForm &&
              <Link to={props.item.link} className={
                  `${props.item.className ? props.item.className : ''} ${viewHeader.vehicleNavigation ? 'active': ''}`
              }>{props.item.name}</Link>
          }
          {
              props.item.isLocal && !props.item.isVehicleForm &&
              <NavLink to={props.item.link} className={props.item.className ? props.item.className : ''}>{props.item.name}</NavLink>
          }
          {
              !props.item.isLocal &&
              <a href={props.item.link} target="_blank" rel="noopener noreferrer" className={props.item.className ? props.item.className : ''}>{props.item.name}</a>
          }
      </>
    );
}, (prevProps, nextProps) => {

        return true;
    });

export default ButtonHeaderNav;
