import React, {useContext} from "react";

import GlobalDataContext from "../../../../../contexts/GlobalDataContext";
import ViewContext from "../../../../../contexts/ViewContext";
import {viewHeaderActionTypes} from "../../../../../contexts/ViewContext/viewContextActions";

import ButtonLogo from "../../../../ui/header/ButtonLogo";
import DesktopHeaderDropDown from "./DesktopHeaderDropDown";

import downArrowIcon from "../../../../../resources/images/down-arrow-white.svg";
import upArrowIcon from "../../../../../resources/images/up-arrow-white.svg";
import ButtonBackHome from "../../../../ui/header/ButtonBackHome";

const AppHeaderDesktop = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const {viewHeader, updateViewHeader} = useContext(ViewContext);

    return(
        <div className="navbarContentWrapper">

            <ButtonLogo />
            <div className='linksWrapper'>
                <ButtonBackHome />

                {
                    globalData.isAuth &&
                        <h3 onClick={()=>{updateViewHeader({type:viewHeaderActionTypes.UpdateDesktopHeader, payload:!viewHeader.isDesktopOpen})}}>
                            {globalData.first_name}
                            <span>
                                    {
                                        !viewHeader.isDesktopOpen &&
                                        <img src={downArrowIcon} alt="downArrow"/>
                                    }
                                {
                                    viewHeader.isDesktopOpen &&
                                    <img src={upArrowIcon} alt="upArrow"/>
                                }
                                </span>
                        </h3>
                }
                {
                    viewHeader.isDesktopOpen &&
                    <DesktopHeaderDropDown signOut={props.signOut} />
                }
            </div>
        </div>
    );
};

export default AppHeaderDesktop;
