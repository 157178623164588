import {calculateMinDates} from "../../../helpers/calculateGLicence";
import {driverVehlinkInitialState, minDateInitialState} from "./initialValues";


export const getDriverLicenceInitialState = (licenceAge = 16) => {
    return {
        licence_type: "",
        first_licence_age: licenceAge,
        previous_licence: false,
        g_licence_date: '',
        g1_licence_date: '',
        g2_licence_date: '',
        passed_driver_training: false,
        isOnlyG: false
    }
}

export const getDriverDataInitialState = (licenceAge = 16) => {
    return {
        first_name: "",
        date_of_birth: "",
        marital_status: "S",
        gender: "M",
        occupation: 998,
        licence_info: {...getDriverLicenceInitialState(licenceAge)},
        applicant_relationship: "",
        listed: true,
        listed_year: "",
        insured: true,
        insured_year: "",
        insurance_cancellation: false,
        insurance_cancellation_list: [{reason: '', end_date: '', start_date: ''}],
        licence_suspension: false,
        licence_suspension_list: [{reason: '', reinstatement_date: '', suspension_date: ''}],
        accident: false,
        accident_list: [{accident_date: ''}],
        ticket: false,
        ticket_list: [{reason: '', ticket_date: ''}],
        vehlink: {...driverVehlinkInitialState},
        minDates: {...minDateInitialState}
    }
}

export const getLocalDrivers = (
    licenceAge, driversLocal
) => ({
    items: driversLocal?.items?.map((item) => {
            if (!item.minDates) {
                /* update localStorage items which do not have minDates */
                return calculateMinDates({...getDriverDataInitialState(licenceAge), ...item});
            } else {
                return {...getDriverDataInitialState(licenceAge), ...item};
            }
        }) ||
        [{
            ...getDriverDataInitialState(licenceAge)
        }]
});
