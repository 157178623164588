import React from "react";
import FormTabWrapper from "../../ui/FormTab";
import FormOneWrapper from "./FormOneWrapper";

const FormOne = () => (
    <div className="add-vehicles-content-wrapper">
        <div className="addVehiclesFormContentWrapper">
            <div className="controlAndTabsWrapper">
                <FormTabWrapper isVehicle={true} isDriver={false} isRecalculate={false}/>
                <FormOneWrapper/>
            </div>
        </div>
    </div>
);

export default FormOne;
