export const setLocalPostalGlobalState = (data) => {
    localStorage.setItem("postal", JSON.stringify({...data}));
};

export const clearLocalPostalGlobalState = () => {
    localStorage.setItem("postal", JSON.stringify({}));
};

export const getLocalPostalGlobalState = (id) => {
    const postalLocal = JSON.parse(localStorage.getItem(`${id}_postal`));

    return{
        isPostal: !!(postalLocal?.postal_code && postalLocal?.location_index && postalLocal?.province_code),
        postal: {
            postal_code: postalLocal?.postal_code || '',
            location_index: postalLocal?.location_index || '',
            city: postalLocal?.city || '',
            province_code: postalLocal?.province_code || '',
            province_name: postalLocal?.province_name || '',
        }
    };
};
