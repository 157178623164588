import {formConfigActionTypes} from "./FormConfigActions";
import {provinceList} from '../../../config/provinceConfig';
import {provinceArea} from "../../../helpers/provinceArea";

function updateFormConfig(areaCode, state){

    const newState = {
        ...state, ...provinceArea(areaCode)
    };

    localStorage.setItem("formConfig", JSON.stringify(newState));

    return newState;
}

export const formConfigReducer = (state, action) => {

    switch (action.type) {

        case formConfigActionTypes.SetConfig:
            return updateFormConfig(action.payload, state);

        case formConfigActionTypes.ClearConfig:
            return updateFormConfig(provinceList[0], state);

        default:
            return state;

    }
}
