import React from "react";

const ButtonBackHome = () => {
    return(
        <div className='the-big-back'>
            <a href='https://www.thebig.ca/' target="_self" rel="noopener noreferrer" >
                home
            </a>
        </div>
    );
};

export default ButtonBackHome;
