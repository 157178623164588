import React from "react";
import PrimaryToolTip from "../tooltips/PrimaryToolTip";

const VehicleInputTitleWithToolTip = ({title, id, description, extraClassName}) => (
        <div className={`formLabelAndTooltipWrapper ${extraClassName ? extraClassName : ''}`}>
            <h4>{title}</h4>
            <div className="tooltipIcon" data-tip={true} data-for={id} />
            <PrimaryToolTip id={id} description={description}/>
        </div>
);

export default VehicleInputTitleWithToolTip;
