import React, {useContext, useEffect, useState} from "react";

import ViewContext from "../../../../../contexts/ViewContext";
import {viewHeaderActionTypes} from "../../../../../contexts/ViewContext/viewContextActions";

import {userPrivatePages} from "../constants";
import ButtonHeaderNav from "../../../../ui/header/ButtonHeaderNav";
import ButtonSignOut from "../../../../ui/header/ButtonSignOut";

const DesktopHeaderDropDown = (props) => {

    const [isClose, setIsClose] = useState(false);

    const {updateViewHeader} = useContext(ViewContext);

    function focusOutHandler(){
        setIsClose(true);
    }

    useEffect(()=>{

        document.body.addEventListener('mouseup',focusOutHandler);

        return ()=>{
            document.body.removeEventListener('mouseup',focusOutHandler);
        }
    },[]);

    useEffect(()=>{

        let closeTimeOut;

        if(isClose){
            closeTimeOut = setTimeout(()=>{
                updateViewHeader({type:viewHeaderActionTypes.UpdateDesktopHeader, payload:false});
            },200);

        }

        return ()=>{
            clearTimeout(closeTimeOut);
        }
    },[isClose]);

    return (
        <div className="userMenuWrapper">
            {
                userPrivatePages.map((item, index) => {
                    return (
                        <ButtonHeaderNav item={item} key={`desktop-nav-user-logged-in-${index}`}/>
                    )
                })
            }
            <ButtonSignOut signOut={props.signOut}/>
        </div>
    );
}

export default DesktopHeaderDropDown;
