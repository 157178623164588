import React, {useContext} from "react";

import {Link} from "react-router-dom";

import ViewContext from "../../../../contexts/ViewContext";
import GlobalDataContext from "../../../../contexts/GlobalDataContext";

import {vehicleSteps} from "./constants";
import FormControlButtons from "./FormControlButtons";


const VehicleMobileHeader = () => {


    const {globalData} = useContext(GlobalDataContext);

    const {viewHeader, viewSize} = useContext(ViewContext);



    return (
        <>
            <div className="postalCodeDataMobile">
                <h2>Car Insurance Quote</h2>
                {
                    globalData.isPostal &&
                    <p>Your Postal Code: {globalData.postal.postal_code}</p>
                }
            </div>
            <div className="addVehiclesFormNavBar">
                <div className="addVehiclesFormNavBarContent">
                    <div className="multiStepContentWrapper">
                        <div className="postalCodeData">
                            <h2>Car Insurance Quote</h2>
                            {
                                globalData.isPostal &&
                                <p>Your Postal Code: {globalData.postal.postal_code}</p>
                            }
                        </div>

                        <ol className='progtrckr'>
                            {
                                vehicleSteps.map((s, i) => (
                                    <li
                                        className={`progtrckr-${
                                            viewHeader.vehicleId <= i ?
                                                viewHeader.vehicleId === i ?
                                                    'doing' :
                                                    'todo'
                                                : 'done'
                                        }`}
                                        key={i}
                                        value={i}
                                    >
                                        <Link to={vehicleSteps[i].path} key={i}>
                                            <img className='multiStepIcon' src={vehicleSteps[i].icon}
                                                 alt={vehicleSteps[i].icon}/>
                                            <div>
                                                <span>{viewSize.isMobile ? `${vehicleSteps[i].name} / 4` : vehicleSteps[i].name}</span>
                                                <span className='multiStepTitle'>{vehicleSteps[i].title}</span>
                                            </div>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ol>

                        <FormControlButtons />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleMobileHeader;
