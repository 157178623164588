import carIcon from "../../../../resources/images/car.svg";
import licenseIcon from "../../../../resources/images/driving-license.svg";
import discountIcon from "../../../../resources/images/discount.svg";
import paperIcon from "../../../../resources/images/paper.svg"

export const vehicleSteps = [
    {key: 0, path: `/`, name: 'Step 1', title: 'Vehicle Info', icon: carIcon},
    {key: 1, path: `/driver`, name: 'Step 2', title: 'Driver Info', icon: licenseIcon},
    {key: 2, path: `/info`, name: 'Step 3', title: 'Discount Info', icon: discountIcon},
    {key: 3, path: `/quote`, name: 'Step 4', title: 'Your Quotes', icon: paperIcon}
];
