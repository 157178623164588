import React, {useEffect, useState} from "react";

const InputCheckBox = (props) => {
    const { onChange, checked, name, rootName, description } = props;
    const [ localValue, setLocalValue ] = useState(checked);
    const [ isFirst, setIsFirst ] = useState(true);

    useEffect(() => {
        if(!isFirst) {
            onChange(
                {
                    target: {
                        name: name,
                        value: localValue,
                    }
                },
                rootName
            );
        }
    }, [localValue]);

    useEffect(()=>{
        setIsFirst(false);
    },[]);

    return (
        <div className="checkbox" onMouseDown={() => {
            setLocalValue(!localValue)
        }}>
            <input
                type="checkbox"
                readOnly
                checked={localValue}
                value={!localValue ? 'on' : 'off'}
            />
            <label>
                <span>
                    {
                        description
                    }
                </span>
            </label>
        </div>
    );
};

export default InputCheckBox;
