import React, {useContext, useEffect, useReducer} from "react";
import cloneDeep from 'lodash/cloneDeep';
import {discountDataInitialState, discountDataReducer} from "./discountDataReducer";
import ViewContext from "../../ViewContext";
import {viewPageActionTypes} from "../../ViewContext/viewContextActions";


const DiscountDataContext = React.createContext();

const discountDataLocal = JSON.parse(localStorage.getItem("discountData"));

/* will be removed during MCH-617 */
if(discountDataLocal?.quoter_info){
    if(!discountDataLocal?.quoter_info?.casl_consent) {
        discountDataLocal.quoter_info.casl_consent = false;
    }
}

export const DiscountDataContextProvider = (props) => {

    const [discountData, updateDiscountData] = useReducer(discountDataReducer,
        discountDataLocal ? {
            ...cloneDeep(discountDataInitialState),
            ...discountDataLocal
        } : {
            ...cloneDeep(discountDataInitialState)
        }
    );

    const {updateViewPage} = useContext(ViewContext);

    useEffect(()=>{
        updateViewPage({type: viewPageActionTypes.UpdateQuoteRequest, payload: true});
    },[discountData, updateViewPage]);

    return (
        <DiscountDataContext.Provider value={{
            discountData, updateDiscountData
        }}>
            {
                props.children
            }
        </DiscountDataContext.Provider>
    )
}

export default DiscountDataContext;
