import React, {useReducer} from "react";
import {globalDataReducer} from "./globalDataReducer";

const GlobalDataContext = React.createContext();

const postalLocal = JSON.parse(localStorage.getItem('postal'));

let isPostal = false;
if(postalLocal){
    if(postalLocal.city && postalLocal.location_index && postalLocal.postal_code){
        isPostal = true;
    }
}


export const globalDataInitialState = {
    uid: '',
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    isAuth: false,
    signedInRedirect: '',
    isPostal: isPostal,
    postal:{
        postal_code: postalLocal ? postalLocal.postal_code ? postalLocal.postal_code : '' : '',
        location_index: postalLocal ? postalLocal.location_index ? postalLocal.location_index : '' : '',
        city: postalLocal ? postalLocal.city ? postalLocal.city : '' : '',
        original_postal_code: postalLocal ? postalLocal.original_postal_code ? postalLocal.original_postal_code : '' : '',
    }
};


export const GlobalDataContextProvider = (props) => {

    const [globalData, updateGlobalData] = useReducer(globalDataReducer, globalDataInitialState);

    return (
        <GlobalDataContext.Provider value={{
            globalData,
            updateGlobalData
        }}>{
            props.children
        }
        </GlobalDataContext.Provider>
    );

};

export default GlobalDataContext;
