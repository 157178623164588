import React from "react";
import theBigLogotype from "../../../resources/images/the-big-logotype.png";

const ButtonLogo = () => {
    return(
        <div className='the-big-logotype'>
            <img src={theBigLogotype} alt='the-big-logotype' />
        </div>
    )
};

export default ButtonLogo;
