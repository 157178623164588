import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import DiscountDataContext, {discountDataActionTypes} from "../../../../../../contexts/formContexts/DiscountDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";

const DriverName = () => {

    const {driverTab, driverData, updateDriverData, updateDriverTab} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const handleInput = useCallback((event)=>{

        const firstName = event.target.value;

        updateDriverData({type:driverDataActionTypes.UpdateFirstName, index:driverTab.activeItem, payload: firstName});
        updateDriverTab({type: driverDataActionTypes.UpdateTabName, index: driverTab.activeItem, payload: firstName});

        if(driverTab.activeItem===0) updateDiscountData({
            type: discountDataActionTypes.UpdateQuoterFirstName,
            payload: firstName
        });

    },[updateDriverData, driverTab.activeItem, updateDriverTab, updateDiscountData]);

    return(
        <>
            <VehicleInputTitleWithToolTip title="What is your legal first name?" id="legalName" description="The name on a policy should match the one on your official driver’s licence."/>

            <input
                name='first_name'
                defaultValue={driverData.items[driverTab.activeItem].first_name}
                className="formInput"
                placeholder="Driver First Name"
                onBlur={(event) => handleInput(event)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleInput(event);
                    }
                }}
            />
        </>
    );
}

export default DriverName;
