import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom";

import GlobalDataContext from "../../contexts/GlobalDataContext";

const PrivateRoute = ({children, ...rest}) => {

    const {globalData} = useContext(GlobalDataContext);

    return (
        <Route exact
            {...rest}
            render={({location}) =>
                globalData.isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/accounts/signin",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
