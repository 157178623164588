import React, {useContext, useEffect, useReducer} from "react";
import {vehicleDataReducer, vehicleTabItemInitialState, vehicleItemInitialState, vehicleTabReducer} from "./vehicleDataReducer";
import ViewContext from "../../ViewContext";
import {viewPageActionTypes} from "../../ViewContext/viewContextActions";

const vehicleDataInitialState = {
    items: [{...vehicleItemInitialState}]
};

const vehicleTabInitialState = {
    tabs: [{...vehicleTabItemInitialState}],
    activeItem: 0,
}

const vehicleDataLocal = JSON.parse(localStorage.getItem("vehicleData"));

const vehicleTabLocal = JSON.parse(localStorage.getItem("vehiclesTab"));

const VehicleDataContext = React.createContext();

export const VehicleDataContextProvider = (props) => {

    const [vehicleData, updateVehicleData] = useReducer(vehicleDataReducer, vehicleDataLocal || vehicleDataInitialState);

    const [vehicleTab, updateVehicleTab] = useReducer(vehicleTabReducer, vehicleTabLocal || vehicleTabInitialState);

    const {updateViewPage} = useContext(ViewContext);

    useEffect(()=>{
        updateViewPage({type: viewPageActionTypes.UpdateQuoteRequest, payload: true});
    },[vehicleData, vehicleTab, updateViewPage]);

    return (
        <VehicleDataContext.Provider value={{
            vehicleData, updateVehicleData,
            vehicleTab, updateVehicleTab
        }}>
            {
                props.children
            }
        </VehicleDataContext.Provider>
    )
}

export default VehicleDataContext;
