import React, {useContext} from "react";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";

const AddVehicleDriverButton = () => {

    const {vehicleData, updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);

    const addVehicleHandler = () =>{
        updateVehicleData({type:vehicleDataActionTypes.AddItem});
        updateVehicleTab({type:vehicleDataActionTypes.AddItem});
        updateVehicleTab({type:vehicleDataActionTypes.SelectItem, payload: (vehicleData.items.length-1)});
        window.scrollTo(0,0);
    }

    return(
      <>
          {
              vehicleData.items.length<5 &&
              <div className='bottom-navigation-btns-wrapper'>
                  <button onClick={addVehicleHandler} className='bottom-adddriver-btn'>Add A Vehicle
                  </button>
              </div>
          }
      </>
    );
}
export default AddVehicleDriverButton;
