import {UseFormInputTypes} from "../../../helpers/UseForm/constatns";
import {InputTypes} from "../../ui/Inputs/constants";

export const Initial_SignUpFormData = [
    {
        title: 'First Name *',
        name: 'first_name',
        toolTip: '',
        defaultErrorMessage: 'Please enter your First Name',
        placeholder: 'First Name',
        formName: 'first_name',
        type: 'text',
        autoComplete: 'first-name',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.NAME,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'Last Name *',
        name: 'last_name',
        toolTip: '',
        defaultErrorMessage: 'Please enter your Last Name',
        placeholder: 'Last Name',
        formName: 'last_name',
        type: 'text',
        autoComplete: 'last-name',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.NAME,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'Gender *',
        name: 'gender',
        toolTip: '',
        defaultErrorMessage: 'Please choose Your Gender',
        placeholder: '',
        formName: 'gender',
        type: 'switch',
        autoComplete: 'last-name',
        componentType: InputTypes.SWITCH,
        validationType: UseFormInputTypes.SWITCH,
        switchItems:[
            {
                name: 'Male',
                id: 0,
                value: 'Male'
            },
            {
                name: 'Female',
                id: 1,
                value: 'Female'
            },
            {
                name: 'Other',
                id: 2,
                value: 'Other'
            }
        ],
        localValue: 0,
        value: 'Male',
        errorMessage: '',
        isValid: true
    },
    {
        title: 'E-mail *',
        name: 'email',
        toolTip: '',
        defaultErrorMessage: 'Please enter your email',
        placeholder: 'example@example.com',
        formName: 'email',
        type: 'email',
        autoComplete: 'username',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.EMAIL,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'Password *',
        name: 'password1',
        toolTip: 'Password must be at least 8 characters long and include 1 number',
        defaultErrorMessage: 'Please enter your password',
        placeholder: 'Password',
        formName: 'password1',
        type: 'password',
        autoComplete: 'password',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.PASSWORD,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'Confirm Password *',
        name: 'password2',
        toolTip: '',
        defaultErrorMessage: 'Please enter your password',
        placeholder: 'Password',
        formName: 'password2',
        type: 'password',
        autoComplete: 'password',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.PASSWORD_CONFIRM,
        value: '',
        errorMessage: '',
        isValid: false
    }
];
