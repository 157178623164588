import {useContext, useEffect, useReducer} from "react";

import axios from "axios";
import httpRequest from "../../services/httpRequest";

import cloneDeep from 'lodash/cloneDeep';

/* constants */
import {requestStatusTypes, QUOTE_FORM_API} from "../../config/constants";
import {
    formLicenceTypes,
    formOptionsInsuranceCancellation
} from '../../config/formConstants';


// contexts
import GlobalDataContext from "../../contexts/GlobalDataContext";
import ViewContext from "../../contexts/ViewContext/ViewContext";
import {
    viewLoaderActionTypes,
    viewPageActionTypes,
    viewRedirectActionTypes
} from "../../contexts/ViewContext/viewContextActions";

import DriverDataContext from "../../contexts/formContexts/DriverDataContext";
import VehicleDataContext from "../../contexts/formContexts/VehicleDataContext";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";
import QuoteDataContext from "../../contexts/formContexts/QuoteDataContext";
import {quoteDataActionTypes} from "../../contexts/formContexts/QuoteDataContext/quoteDataActions";


//validations
import StepThreeValidationManager from "../ValidationManagers/StepThreeValidationManager";

/* locals */
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";
import {AuthDataManager} from "./AuthDataManager";
import {formatPhoneObject} from "../../helpers/formatUtils";


export const QuoteDataManager = () => {

    const {stepThreeValidationPartOne, stepThreeValidationPartTwo} = StepThreeValidationManager();


    const {updateViewLoader, updateViewRedirect, updateViewPage} = useContext(ViewContext);

    const {quoteData, updateQuoteData} = useContext(QuoteDataContext);

    const {signOut} = AuthDataManager();


    const {vehicleData} = useContext(VehicleDataContext);
    const {driverData} = useContext(DriverDataContext);
    const {discountData} = useContext(DiscountDataContext);
    const {globalData} = useContext(GlobalDataContext);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: [],
        cancelTokenSource: axios.CancelToken.source()

    });

    function defaultLoadingState(isLoader, loaderDescription) {
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        if (isLoader) {
            updateViewLoader({
                type: viewLoaderActionTypes.OpenLoader,
                payload: true,
                message: loaderDescription ? loaderDescription : 'Please wait while we fetch your lowest rates from our insurance partners.'
            });
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
    }

    function defaultErrorState(response) {
        if (response.action === requestStatusTypes.UserSignOut) {
            signOut();
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
    }


    const constructRequestBody = async (isQuoter, isRate) => {

        updateQuoteData({type: quoteDataActionTypes.UpdateCallMessage, payload: false});

        let localDrivers = [];
        let localVehicles = [];
        let localDiscount = cloneDeep(discountData);
        let localVehlinks = [];


        // driver

        for (let driverIndex = 0; driverIndex < driverData.items.length; driverIndex++) {
            let newDriver = {...driverData.items[driverIndex]};

            newDriver.licence_info = {...driverData.items[driverIndex].licence_info};

            if (newDriver.applicant_relationship === '') {
                delete newDriver.applicant_relationship;
            }

            if (newDriver.gender === 'other') {
                newDriver.gender = 'M';
            }

            const insuranceCancelValue = formOptionsInsuranceCancellation.filter((item)=>(item.name==='Cancelled for non-payment')).map((item) => (item.value))[0];

            const insuranceNonPaymentList = newDriver.insurance_cancellation_list.filter((item)=>(item.reason===insuranceCancelValue));

            if (
                !newDriver.listed ||
                newDriver.licence_info.licence_type === formLicenceTypes.gOne ||
                newDriver.licence_info.licence_type === formLicenceTypes.gTwo ||
                newDriver.insurance_cancellation && insuranceNonPaymentList.length > 1) {
                    updateQuoteData({type: quoteDataActionTypes.UpdateCallMessage, payload: true});
                    localDiscount.quoter_info.splash_screen = true;
            }

            if (!newDriver.listed) {
                delete newDriver.listed_year;
                newDriver.insured = false;
            }
            if (!newDriver.insured) {
                delete newDriver.insured_year;
            }
            // licence
            if (newDriver.licence_info.isOnlyG) {
                delete newDriver.licence_info.g1_licence_date;
                delete newDriver.licence_info.g2_licence_date;
                delete newDriver.licence_info.passed_driver_training;
            } else {
                if (newDriver.licence_info.licence_type === formLicenceTypes.gOne) {
                    delete newDriver.licence_info.g2_licence_date;
                    delete newDriver.licence_info.g_licence_date;
                }
                if (newDriver.licence_info.licence_type === formLicenceTypes.gTwo) {
                    delete newDriver.licence_info.g_licence_date;
                } else {
                    delete newDriver.licence_info.passed_driver_training;
                }
            }
            delete newDriver.licence_info.isOnlyG;

            // lists

            if (!newDriver.insurance_cancellation) {
                delete newDriver.insurance_cancellation_list;
            } else {
                newDriver.insurance_cancellation_list = [];
                for (let iX = 0; iX < driverData.items[driverIndex].insurance_cancellation_list.length; iX++) {
                    newDriver.insurance_cancellation_list.push({
                        reason: driverData.items[driverIndex].insurance_cancellation_list[iX].reason,
                        start_date: driverData.items[driverIndex].insurance_cancellation_list[iX].start_date,
                        end_date: driverData.items[driverIndex].insurance_cancellation_list[iX].end_date
                    })
                }

            }
            if (!newDriver.licence_suspension) {
                delete newDriver.licence_suspension_list;
            } else {
                newDriver.licence_suspension_list = [];
                for (let sX = 0; sX < driverData.items[driverIndex].licence_suspension_list.length; sX++) {
                    newDriver.licence_suspension_list.push({
                        reason: driverData.items[driverIndex].licence_suspension_list[sX].reason,
                        reinstatement_date: driverData.items[driverIndex].licence_suspension_list[sX].reinstatement_date,
                        suspension_date: driverData.items[driverIndex].licence_suspension_list[sX].suspension_date
                    })
                }
            }
            if (!newDriver.accident) {
                delete newDriver.accident_list;
            } else {
                newDriver.accident_list = [];
                for (let aX = 0; aX < driverData.items[driverIndex].accident_list.length; aX++) {
                    newDriver.accident_list.push({
                        accident_date: driverData.items[driverIndex].accident_list[aX].accident_date
                    });
                }
            }
            if (!newDriver.ticket) {
                delete newDriver.ticket_list;
            } else {
                newDriver.ticket_list = [];
                for (let tX = 0; tX < driverData.items[driverIndex].ticket_list.length; tX++) {
                    newDriver.ticket_list.push({
                        reason: driverData.items[driverIndex].ticket_list[tX].reason,
                        ticket_date: driverData.items[driverIndex].ticket_list[tX].ticket_date
                    });
                }
            }
            localDrivers.push(newDriver);
        }


        //vehicle

        for (let vehicleIndex = 0; vehicleIndex < vehicleData.items.length; vehicleIndex++) {
            let newVehicle = {...vehicleData.items[vehicleIndex], ...globalData.postal};

            //vehlinks
            for (let drX = 0; drX < localDrivers.length; drX++) {
                let forVehLink = {
                    driver_index: Number(drX + 1),
                    vehicle_index: Number(vehicleIndex + 1),
                    priority: localDiscount.vehLink[vehicleIndex].driver_index === drX ? 'Prn' : 'Occ'
                };
                localVehlinks.push(forVehLink);
            }

            if (newVehicle.primary_use === 'personal') {
                delete newVehicle.distance_business;
            }
            newVehicle.comprehensive = {...vehicleData.items[vehicleIndex].comprehensive}
            newVehicle.collision = {...vehicleData.items[vehicleIndex].collision}
            if (!newVehicle.collision.coverage) {
                newVehicle.collision.deductible = 1000;
            }
            if (newVehicle.collision.deductible === 0) {
                newVehicle.collision.coverage = false;
                newVehicle.collision.deductible = 1000;
            }
            if (!newVehicle.comprehensive.coverage) {
                newVehicle.comprehensive.deductible = 1000;
            }
            if (newVehicle.comprehensive.deductible === 0) {
                newVehicle.comprehensive.coverage = false;
                newVehicle.comprehensive.deductible = 1000;
            }

            localVehicles.push(newVehicle);
        }

        delete localDiscount.vehLink;

        if (!localDiscount.isAlert || !isQuoter) {
            delete localDiscount.alert;
            delete localDiscount.isAlert;
        } else {
            delete localDiscount.isAlert;
        }

        if (localDiscount.quoter_info.driver_license.length < 1) {
            delete localDiscount.quoter_info.driver_license;
        }

        if (Object.entries(localDiscount.quoter_info.broker_info).length === 0) {
            delete localDiscount.quoter_info.broker_info;
        }
        else{
            if(localDiscount.quoter_info.broker_info.phone){
                const phoneNumber = `${formatPhoneObject(localDiscount.quoter_info.broker_info.phone).phoneNumber}${formatPhoneObject(localDiscount.quoter_info.broker_info.phone).phoneSpecialKeys}`;
                localDiscount.quoter_info.broker_info.phone = phoneNumber;
            }
        }

        if (localDiscount.newUser) {
            delete localDiscount.newUser;
        }

        if (!isRate) {
            delete localDiscount.quoter_info;
        }

        return {
            drivers: [...localDrivers],
            vehicles: [...localVehicles],
            ...globalData.postal,
            ...localDiscount,
            vehlinks: [...localVehlinks]
        }
    }


    const postRequestQuote = async (isQuoter, noRedirect, isLoader, isRate) => {

        let checked = false;
        let errorMessage = '';

        if (isQuoter) {
            errorMessage = stepThreeValidationPartTwo();
            if (errorMessage) {
                updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: errorMessage});
            } else {
                checked = true;
            }
        } else {
            if (!stepThreeValidationPartOne()) {
                updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: 'Please enter your email'});
            } else {
                checked = true;
            }
        }

        if (checked) {

            isRate ?
                defaultLoadingState(isLoader, 'Please note: As insurance premiums are based on the information you provide it is important that it be 100% accurate and up to date. If you are not sure it is best to check and edit your information in the next step.')
                : defaultLoadingState(isLoader);

            const requestBody = await constructRequestBody(isQuoter, isRate);

            updateViewPage({type: viewPageActionTypes.UpdateQuoteRequest, payload: false});

            if (requestBody) {

                const response = await httpRequest(QUOTE_FORM_API.POST_QUOTE_DATA, {
                    method: 'post',
                    body: JSON.stringify(requestBody)
                }, localState.cancelTokenSource.token);

                if (response) {
                    if (response.status === 200 || response.status === 201) {
                        updateLocalState({
                            type: DataManagerActionTypes.UpdateQuoteResponse,
                            payload: [...response.data]
                        });
                        if (response.data.length > 0) {
                            updateQuoteData({
                                type: quoteDataActionTypes.UpdateQuote,
                                payload: [...response.data],
                                isRated: isRate
                            });
                        } else {
                            updateQuoteData({type: quoteDataActionTypes.UpdateQuote, payload: [], isRated: isRate});
                        }
                        updateLocalState({
                            type: DataManagerActionTypes.RequestState,
                            payload: requestStatusTypes.Success
                        });
                        updateLocalState({
                            type: DataManagerActionTypes.UpdateSuccessMessage,
                            payload: 'REQUEST_QUOTE_Success'
                        });
                        if (isQuoter) {
                            window.scrollTo(0, 0);
                        }
                        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
                        if (isRate) {
                            if (!quoteData.quotes.length) {
                                updateLocalState({
                                    type: DataManagerActionTypes.UpdateErrorMessage,
                                    payload: 'Network error'
                                });
                                updateQuoteData({type: quoteDataActionTypes.UpdateRate, payload: false});
                            }

                        }
                    } else {
                        defaultErrorState(response);
                    }
                }

            }
        } else {
            if (!isQuoter && !noRedirect) {
                updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: '/info'});
            }
        }

    };

    const getQuotes = async () => {

        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateViewLoader({type: viewLoaderActionTypes.OpenLoader, payload: true, message: ''});


        const response = await httpRequest(QUOTE_FORM_API.GET_QUOTE_DATA, {
            method: 'get',
            body: JSON.stringify({})
        }, localState.cancelTokenSource.token);

        if (response) {
            if (response.status === 200 || response.status === 201) {
                updateLocalState({
                    type: DataManagerActionTypes.UpdateQuoteResponse,
                    payload: [...response.data]
                });
                updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
            } else {
                defaultErrorState(response);
            }
        }
    }

    useEffect(() => {
        return () => {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        quoteRequestState: localState.requestState,
        quoteResponseData: localState.responseData,
        quoteSuccessMessage: localState.SuccessMessage,
        quoteErrorMessage: localState.errorMessage,
        postRequestQuote,
        getQuotes
    });
};
