import React, {useContext} from "react";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {FormConfigStateContext} from "../../../contexts/formContexts/FormConfigState";

const AddDriverButton = () => {

    const {driverData, updateDriverData, updateDriverTab} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);

    const addDriverHandler = () => {
        updateDriverData({type: driverDataActionTypes.AddItem, payload: formConfig.licenceConfig.licenceAge});
        updateDriverTab({type: driverDataActionTypes.AddItem});
        updateDriverTab({type: driverDataActionTypes.SelectItem, payload: (driverData.items.length-1)});
        window.scrollTo(0,0);
    }

    return(
      <>
          {
              driverData.items.length<5 &&
              <div className='bottom-navigation-btns-wrapper'>
                  <button onClick={addDriverHandler} className="bottom-adddriver-btn">
                      Add A Driver
                  </button>
              </div>
          }
      </>
    );
};

export default AddDriverButton;
