import React, {useContext} from "react";
import CallButton from "../../ui/buttons/CallButton";
import {formatTitleToCapital} from '../../../helpers/formatUtils';
import ButtonLogo from "../../ui/header/ButtonLogo";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import SpecialPhone from "./SpecialPhone";

const SplashScreen = () => {

    const {discountData} = useContext(DiscountDataContext);

    const {phoneString} = SpecialPhone({});

    return (
        <div className='splash-screen-wrapper'>
            <h2>We Aren’t Done Yet</h2>
            This quote requires us to get a bit more information from you, please give
            {
                discountData.quoter_info.broker_info.id &&
                ` ${formatTitleToCapital(discountData.quoter_info.broker_info.id)} `
            }
            {
                !discountData.quoter_info.broker_info.id && ' us '
            }
            a call at
            {
                phoneString &&
                <CallButton phoneString={phoneString}/>
            }
            and we will be happy to get you the best rate.
            <ButtonLogo/>
        </div>
    );
}
;

export default SplashScreen;
