import React, {useReducer} from "react";
import {quoteDataReducer} from "./quoteDataReducer";
import {quoteDataInitialState} from "./constants";

const QuoteDataContext = React.createContext();

const quotesInitialLocal = JSON.parse(localStorage.getItem("quoteData"));

export const QuoteDataContextProvider = (props) => {

    const [quoteData, updateQuoteData] = useReducer(quoteDataReducer,quotesInitialLocal || {...quoteDataInitialState});


    return(
        <QuoteDataContext.Provider value={{
            quoteData, updateQuoteData
        }}>
            {
                props.children
            }
        </QuoteDataContext.Provider>
    )
}

export default QuoteDataContext;
